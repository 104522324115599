import * as env from './env';

export const VERSION = process.env.REACT_APP_VERSION || "Development Version"
export const ENVIRONMENT = process.env.REACT_APP_ENV
export const API_URL = process.env.REACT_APP_API_URL || env.invest[ENVIRONMENT] || env.invest.test;
export const ADMIN_API_URL = `${API_URL}/admin`;
export const SHARED_API_URL = process.env.REACT_APP_SHARED_API_URL || env.shared[ENVIRONMENT] || env.shared.test;
export const FINANCE_APP_URL = process.env.REACT_APP_FINANCE_APP_URL
export const RECAPTCHA = process.env.REACT_APP_RECAPTCHA
export const AUTH_ADMIN_TENANT_ID = process.env.REACT_APP_AUTH_ADMIN_TENANT_ID
