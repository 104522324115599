import React, {useEffect, useState} from 'react';
import {classNames, sharedAccessTokenKey, sharedFetch,} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {formatAddress} from "../utils";
import {ORGANIZATION_STATUS,} from "./consts";
import Spinner from "../components/spinner";
import ViewPartnerDialog from "./viewPartnerDialog";
import SearchCompaniesDialog from "../organizations/searchCompaniesDialog";

const tableColumns = [
    {v: d => d.oid, label: "Company ID", sort: "oid"},
    {v: d => d.name, label: "Name", sort: "name"},
    {v: d => d.taxNo, label: "Tax Number", sort: "taxNo"},
    {v: d => formatAddress(d), label: "Address"},
    {v: d => d.status, label: "Status", sort: "status"},
]

export default function Partner({match}) {
    const now = new Date(),
        today = now.toLocaleDateString('en-CA');

    const logout = useLogout();

    const [viewPartnerDialogOpen, setViewPartnerDialogOpen] = useState(false);
    const [partnerData, setPartnerData] = useState(null);
    const [canEdit, setCanEdit] = useState(true);
    const [documentNo, setDocumentNo] = useState("")
    const [date, setDate] = useState(today)
    const [id, setId] = useState(); // entry editing
    const [organizationId, setOrganizationId] = useState(); // entry editing
    const [isLoading, setIsLoading] = useState(false); // entry editing
    const [companies, setCompanies] = useState([])
    const [invoiceRows, setInvoiceRows] = useState([])

    const [status, setStatus] = useState("")
    const [name, setName] = useState("")
    const [address1, setAddress1] = useState("")
    const [postCode, setPostCode] = useState("")
    const [city, setCity] = useState("")
    const [contactPerson, setContactPerson] = useState("")
    const [title, setTitle] = useState("")
    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("")
    const [isVatLiable, setIsVatLiable] = useState(false)
    const [taxNo, setTaxNo] = useState("")
    const [vatNo, setVatNo] = useState("")
    const [registrationNo, setRegistrationNo] = useState("")
    const [oid, setOid] = useState("")

    const [isSearchCompaniesDialogOpen, setIsSearchCompaniesDialogOpen] = useState(false);

    function fetchData({organizationId, partnerId}) {
        if (organizationId && partnerId) {
            setId(partnerId);
            setOrganizationId(organizationId);
            setIsLoading(true)
            try {
                sharedFetch(`admin/organizations/${organizationId}/partners/${partnerId}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
                    },
                })
                    .then((resData) => {
                        //console.log(resData)
                        if (resData?.statusCode === 401) logout()

                        setTaxNo(resData.taxNo)
                        setVatNo(resData.vatNo)
                        setRegistrationNo(resData.registrationNo)
                        setOid(resData.oid)
                        //setIsVatLiable(resData.isVatLiable)
                        setCountry(resData.address?.country?.name)
                        setCity(resData.address?.city)
                        setContactPerson(resData.contactPerson?.name)
                        setTitle(resData.contactPerson?.title)
                        setPhone(resData.contactPerson?.phone)
                        setPostCode(resData.address?.postCode)
                        setAddress1(resData.address?.address1)
                        setName(resData.name)
                        setStatus(resData.status)

                        setIsLoading(false)

                    });
            } catch (e) {
                console.error(e)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        fetchData(match.params)
    }, [match.params]);

    function goBack() {
        const link = document.createElement('a');
        link.href = "#/partners";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    function prepareSubmitData(showAlerts = true) {
        if (!taxNo) return alert("Tax number is required")
        //if(!oid) return alert("Connected organization ID is required")

        return {
            name,
            taxNo,
            //vatNo,
            registrationNo,
            oid,
            address1: address1,
            city: city,
            postCode: postCode,
            //country: country,
            contactPerson: contactPerson,
            contactTitle: title,
            contactPhone: phone,
            status: status,
        }
    }

    function onSubmit(e) {
        e.preventDefault();


        const object = prepareSubmitData()
        if (!object) return

        const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify(object)

        sharedFetch(`admin/organizations/${organizationId}/partners${id ? ("/" + id) : ""}`, id ? {
            method: 'PUT',
            headers, body
        } : {
            method: 'POST',
            headers, body
        }).then(() => {
            goBack()
        })
    }

    function onChooseCompany(company) {
        if (company?.id) setOid(company.id)
    }

    return (
        <>
            <div className="my-8 space-y-6">

                <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                    {isLoading ? (
                        <Spinner/>
                    ) : (
                        <form onSubmit={onSubmit} className="space-y-8">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                                <div className="sm:mt-5 space-y-6 sm:space-y-5">
                                    <div className=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                        <label htmlFor="status"
                                               className={classNames(
                                                   "block text-sm font-medium sm:mt-px sm:pt-2",
                                                   id ? "text-gray-700" : "text-gray-300"
                                               )}>
                                            Status
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <select
                                                id="status"
                                                name="status"
                                                onChange={e => setStatus(e.target.value)}
                                                value={status}
                                                className={classNames(
                                                    "max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md border-gray-300",
                                                    //!!id ? "border-gray-300 bg-gray-50 text-gray-600" : "border-gray-300"
                                                )}
                                            >
                                                {Object.keys(ORGANIZATION_STATUS).map((status, index) => (
                                                    <option key={index}
                                                            value={status}>{ORGANIZATION_STATUS[status]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="oid"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Company ID
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
                                            <input
                                                type="text"
                                                id="oid"
                                                value={oid}
                                                onChange={e => setOid(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setIsSearchCompaniesDialogOpen(true)}
                                                className="ml-4 text-sm underline text-gray-500 hover:text-gray-900"
                                            >Select company
                                            </button>
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="name"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Name
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="name"
                                                required
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="taxNo"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Tax number
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="taxNo"
                                                required
                                                value={taxNo}
                                                onChange={e => setTaxNo(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    {/*<div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="isVatLiable"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            VAT liable
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2 mx-1 pt-1.5">
                                            <Switch
                                                checked={isVatLiable}
                                                onChange={setIsVatLiable}
                                                className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                            >
                                                <span className="sr-only">VAT liable</span>
                                                <span aria-hidden="true"
                                                      className="pointer-events-none absolute h-full w-full rounded-md bg-white"/>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        isVatLiable ? 'bg-gray-600' : 'bg-gray-200',
                                                        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                                                    )}
                                                />
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        isVatLiable ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                                                    )}
                                                />
                                            </Switch>
                                        </div>
                                    </div>*/}

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="registrationNo"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Registration number
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="registrationNo"
                                                value={registrationNo}
                                                onChange={e => setRegistrationNo(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="address1"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Address
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="address1"
                                                value={address1}
                                                onChange={e => setAddress1(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="postCode"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Post code
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="postCode"
                                                value={postCode}
                                                onChange={e => setPostCode(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="city"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            City
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="city"
                                                value={city}
                                                onChange={e => setCity(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="contactPerson"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Contact person
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="contactPerson"
                                                value={contactPerson}
                                                onChange={e => setContactPerson(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Title
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="title"
                                                value={title}
                                                onChange={e => setTitle(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="phone"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Phone
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="phone"
                                                value={phone}
                                                onChange={e => setPhone(e.target.value)}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    !canEdit ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="country"
                                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Country
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                type="text"
                                                id="country"
                                                disabled={true}
                                                defaultValue={country?.name}
                                                className={classNames(
                                                    "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                    true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <a
                                        href="#/promissory-notes"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        Cancel
                                    </a>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        {id ? "Update" : "Create"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <ViewPartnerDialog data={partnerData} open={viewPartnerDialogOpen} setOpen={setViewPartnerDialogOpen}/>
            <SearchCompaniesDialog onSubmit={onChooseCompany} open={isSearchCompaniesDialogOpen}
                                   setOpen={setIsSearchCompaniesDialogOpen}/>
        </>
    )
};
