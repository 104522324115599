import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import {reportAction} from "../actions/reportsActions";
import {decimalNumberFormatter} from "../helpers/helpers";

const useStyles = makeStyles({
    selection: {
        padding: '0.5em',
        background: '#f2f2f2',
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginTop: '1em',
        marginBottom: '0.5em',
    },
    list: {
        border: '1px solid #cccccc',
        padding: '5px',
    },
});

const FinancialList = ({ title, items, decimalPlaces = 0 }) => {
    const classes = useStyles();

    return (
        <>
            <h2 className={classes.sectionTitle}>{title}</h2>
            <table className={classes.list} width="80%">
                <thead>
                <tr className={classes.list}>
                    <th className={classes.list} width="9%">ID</th>
                    <th className={classes.list} width="9%">AOP</th>
                    <th className={classes.list} width="72%">Description</th>
                    <th className={classes.list} width="10%">Value</th>
                </tr>
                </thead>
                <tbody>
                {items?.map((i, idx) => {
                    return (
                        <tr key={idx} className={classes.list}>
                            <td className={classes.list}>{i.id}</td>
                            <td className={classes.list}>{i.aop}</td>
                            <td className={classes.list}>{i.description}</td>
                            <td className={classes.list} align="right">{decimalNumberFormatter(i.value, decimalPlaces)}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
}

const OwnerList = ({ items }) => {
    const classes = useStyles();

    return (
        <>
            <h2 className={classes.sectionTitle}>Owners</h2>
            <table className={classes.list} width="60%">
                <thead>
                <tr className={classes.list}>
                    <th className={classes.list} width="60%">Owner</th>
                    <th className={classes.list} width="20%">Date</th>
                    <th className={classes.list} width="20%">Ratio</th>
                </tr>
                </thead>
                <tbody>
                {items?.map((i, idx) => <OwnershipList key={idx} item={i} />)}
                </tbody>
            </table>
        </>
    );
}

const OwnershipList = ({ item }) => {
    function getOwnerName(owner) {
        if (owner.foreignCompany) return owner.foreignCompany;
        else if (owner.regNumber) return owner.regNumber;
        else return `${owner.firstName} ${owner.lastName}`;
    }

    return (
        <>
            {item.ownership
                ? item.ownership.map((o, idx) => <OwnerItem key={idx} name={getOwnerName(item)} date={item.date} ratio={o.ratio} />)
                : <OwnerItem name={getOwnerName(item)} date={item.date} ratio="" />
            }
        </>
    )
}

const OwnerItem = ({ name, date, ratio }) => {
    const classes = useStyles();

    return (
        <tr className={classes.list}>
            <td className={classes.list}>{name}</td>
            <td className={classes.list}>{date}</td>
            <td className={classes.list}>{ratio}</td>
        </tr>
    )
}

const RepresentativeList = ({ items }) => {
    const classes = useStyles();

    return (
        <>
            <h2 className={classes.sectionTitle}>Representatives</h2>
            <table className={classes.list} width="80%">
                <thead>
                <tr className={classes.list}>
                    <th className={classes.list} width="25%">First name</th>
                    <th className={classes.list} width="25%">Last name</th>
                    <th className={classes.list} width="20%">Type</th>
                    <th className={classes.list} width="20%">Representation</th>
                    <th className={classes.list} width="10%">Date</th>
                </tr>
                </thead>
                <tbody>
                {items?.map((i, idx) => {
                    return (
                        <tr key={idx} className={classes.list}>
                            <td className={classes.list}>{i.firstName || '-'}</td>
                            <td className={classes.list}>{i.lastName || '-'}</td>
                            <td className={classes.list}>{i.type}</td>
                            <td className={classes.list}>{i.representation}</td>
                            <td className={classes.list}>{i.date}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
}

const BoardMemberList = ({ items }) => {
    const classes = useStyles();

    return (
        <>
            <h2 className={classes.sectionTitle}>Board members</h2>
            <table className={classes.list} width="60%">
                <thead>
                <tr className={classes.list}>
                    <th className={classes.list} width="35%">First name</th>
                    <th className={classes.list} width="35%">Last name</th>
                    <th className={classes.list} width="30%">Type</th>
                </tr>
                </thead>
                <tbody>
                {items?.map((i, idx) => {
                    return (
                        <tr key={idx} className={classes.list}>
                            <td className={classes.list}>{i.firstName}</td>
                            <td className={classes.list}>{i.lastName}</td>
                            <td className={classes.list}>{i.type}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
}

const ContactList = ({ items }) => {
    const classes = useStyles();

    return (
        <>
            <h2 className={classes.sectionTitle}>Contacts</h2>
            <table className={classes.list} width="60%">
                <thead>
                <tr className={classes.list}>
                    <th className={classes.list} width="50%">Type</th>
                    <th className={classes.list} width="50%">Description</th>
                </tr>
                </thead>
                <tbody>
                {items?.map((i, idx) => {
                    return (
                        <tr key={idx} className={classes.list}>
                            <td className={classes.list}>{i.type}</td>
                            <td className={classes.list}>{i.description}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
}

export const AjpesData = ({ availableCompanyReports, ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const companyReport = useSelector(state => state.companyReportData)

    useEffect(() => {
        const year = availableCompanyReports[0]?.['year'];
        const type = availableCompanyReports[0]?.['type'];
        if (year && type) reportAction(dispatch, props.record.id, year, type);
    }, [availableCompanyReports, dispatch, props.record.id]);

    const onSelectionChange = (e) => {
        const [year, type] = e.target.value.split('_');
        reportAction(dispatch, props.record.id, year, type);
    };

    return (
        <div>
            <div className={classes.selection}>
                <label>Available yearly reports: &nbsp;
                    <select id="availableReports" name="availableReports" defaultValue="-" onChange={onSelectionChange}>
                        { availableCompanyReports?.map((r, idx) => <option key={idx} value={`${r.year}_${r.type}`}>{r.year} {r.type}</option>) }
                    </select>
                </label>
            </div>

            { companyReport?.yearlyReport && (
                <div className={classes.sectionTitle}>
                    Risk indicator: &nbsp;
                    {companyReport?.yearlyReport?.riskIndicator?.description}
                </div>
            )}

            { companyReport?.yearlyReport && (<FinancialList title="Financial report" items={companyReport?.yearlyReport?.reportItems} />) }
            { companyReport?.yearlyReport && (<FinancialList title="Financial indicators" items={companyReport?.yearlyReport?.indicators} decimalPlaces={2} />) }

            { !!companyReport?.details?.owners?.length && (<OwnerList items={companyReport?.details?.owners} />) }
            { !!companyReport?.details?.representatives?.length && (<RepresentativeList items={companyReport?.details?.representatives} />) }
            { !!companyReport?.details?.boardMembers?.length && (<BoardMemberList items={companyReport?.details?.boardMembers} />) }
            { !!companyReport?.details?.contacts?.length && (<ContactList items={companyReport?.details?.contacts} />) }
        </div>
    )
}
