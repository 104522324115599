import {useLogout, useRefresh} from "react-admin";
import React, {useCallback, useEffect, useState} from "react";
import {decimalNumberFormatter, httpClient} from "../helpers/helpers";
import * as Config from "../config";
import {StatsList} from "../dashboard/StatsList";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import AppBar from "@material-ui/core/AppBar";
import CardHeader from "@material-ui/core/CardHeader";

const commonColumns = [
    { id: '10', label: '10', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '9', label: '9', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '8', label: '8', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '7', label: '7', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '6', label: '6', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '5', label: '5', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '4', label: '4', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '3', label: '3', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '2', label: '2', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: '1', label: '1', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
];

const columnsRatings = [
    { id: 'rating', label: 'Buyer/Seller rating', minWidth: 40 },
    ...commonColumns,
];

const columnsRatingsRF = [
    { id: '0', label: 'Buyer rating', minWidth: 40 },
    ...commonColumns,
];

export const PriceList = () => {
    const refresh = useRefresh();
    const logout = useLogout();

    const [data, setData] = useState({ f: [], rf: [], scf: [], i: [], c: [] })

    const getPriceList = useCallback(() => {
        return httpClient(`${Config.ADMIN_API_URL}/price_list`
        ).then(data => {
            switch (data.status) {
                case 401: throw new Error("Session expired!");
                default: return data.json
            }
        }).catch(error => {
            return error;
        })
    }, []);

    useEffect(() => {
        getPriceList()
            .then(d => {
                if (d instanceof Error) throw d;
                setData({
                    ...d,
                    f: d.factoringPriceList.ratingPrices.map(i => ({ rating: i.buyerRating, ...i.sellerPrices.reduce((obj, item) => Object.assign(obj, { [item.rating]: item.rate }), {}) })),
                    rf: [d.reverseFactoringPriceList.ratingPrices.reduce((obj, item) => Object.assign(obj, { [item.rating]: item.rate }), {})],
                    scf: d.scfPriceList.ratingPrices.map(i => ({ rating: i.buyerRating, ...i.sellerPrices.reduce((obj, item) => Object.assign(obj, { [item.rating]: item.rate }), {}) })),
                    i: [d.insuranceRates.ratingPrices.reduce((obj, item) => Object.assign(obj, { [item.rating]: item.rate }), {})],
                    c: [d.commissionRates.ratingPrices.reduce((obj, item) => Object.assign(obj, { [item.rating]: item.rate }), {})],
                });
                refresh();
            })
            .catch(e => {
                if (e.status === 401) {
                    logout();
                }
                console.log(e.body || e);
            })
    }, [getPriceList, refresh, logout]);

    return (
        <>
            <br />
            <AppBar position="static" color="inherit" variant="elevation">
                <CardHeader title="Price lists" />
            </AppBar>
            <br />
            <Card>
                <CardContent>
                    <strong>Factoring</strong>
                    <br />
                    <StatsList columns={columnsRatings} items={data.f} hidePagination={true} />
                    <br />
                    <strong>Reverse factoring</strong>
                    <br />
                    <StatsList columns={columnsRatingsRF} items={data.rf} hidePagination={true} />
                    <br />
                    <strong>Supply chain finance</strong>
                    <br />
                    <StatsList columns={columnsRatings} items={data.scf} hidePagination={true} />
                    <br />
                    <strong>Insurance</strong>
                    <br />
                    <StatsList columns={columnsRatingsRF} items={data.i} hidePagination={true} isPercent={true} />
                    <br />
                    <strong>Commission</strong>
                    <br />
                    <StatsList columns={columnsRatingsRF} items={data.c} hidePagination={true} isPercent={true} />
                </CardContent>
            </Card>
        </>
    )
}