import React from 'react';
import {List, Datagrid, TextField, NumberField} from 'react-admin';

const amountOptions = { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 };

export const InterestsByDateList = props => (
    <List {...props} sort={{ field: null, order: null }} bulkActionButtons={false}>
        <Datagrid>
            <NumberField source="year" sortable={false} />
            <NumberField source="month" sortable={false} />
            <TextField source="monthName" sortable={false} />
            <NumberField source="all" sortable={false} options={amountOptions} />
            <NumberField source="autoInvest" sortable={false} options={amountOptions} />
            <NumberField source="invest" sortable={false} options={amountOptions} />
            <NumberField source="persons" sortable={false} options={amountOptions} />
            <NumberField source="companies" sortable={false} options={amountOptions} />
        </Datagrid>
    </List>
);