import { COMPANY_LOOKUP } from '../actions/companyLookupAction';

const CompanyLookupReducer = (previousState = {}, { type, payload}) => {
    if (type === COMPANY_LOOKUP) {
        const companyData = {};
        if (Object.keys(payload).length) {
            companyData['taxNo'] = payload.taxNo;
            companyData['name'] = payload.name;
            companyData['shortName'] = payload.shortName;
            const address = payload.address && payload.address.streetAndNumber ? payload.address.streetAndNumber : payload.address;
            companyData['address'] = address || '';
            companyData['city'] = payload.city || (payload.address ? payload.address.city : '');
            companyData['bankAccounts'] = payload.bankAccounts || [];
        }
        return companyData;
    }
    return previousState;
};

export default CompanyLookupReducer;
