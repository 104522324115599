import React, {useEffect} from 'react';
import {Admin, Resource} from 'react-admin';
import LoginPage from "./login/LoginPage";
import MainLayout from "./components/MainLayout";
import Dashboard from "./dashboard/Dashboard";
import {DocumentEdit, DocumentList} from "./documents/documents";
import {InvestmentCreate, InvestmentEdit, InvestmentList} from "./investments/investments";
import {InvestmentCompanyEdit, InvestmentCompanyList} from "./InvestmentCompany/investmentCompanies";
import InvestmentCompanyCreate from "./InvestmentCompany/InvestmentCompanyCreate";
import {TaxList} from "./taxes/taxes";
import {CustomerEdit, CustomerList} from "./users/users";
import {TransactionCreate, TransactionEdit, TransactionList} from "./transactions/transactions";
import {InterestsByDateList} from "./statistics/interestsByDate";
import {InvestmentsByDateList} from "./statistics/investmentsByDate";
import {NotificationsList} from "./notifications/notifications";
import {TestUserCreate, TestUserList} from "./testUsers/testUsers";
import CompanyIcon from '@material-ui/icons/Business';
import DocumentIcon from '@material-ui/icons/PhotoCamera';
import TaxIcon from '@material-ui/icons/Money';
import TransactionIcon from '@material-ui/icons/AccountBalance';
import UserIcon from '@material-ui/icons/Group';
import EmailIcon from '@material-ui/icons/MailOutline';
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import customRoutes from "./helpers/customRoutes";
import companyLookup from "./reducers/companyLookupReducer";
import {availableReports, companyReport} from "./reducers/reportsReducers";
import customerPriceList from "./reducers/customerPriceListReducer";
import {RecaptchaVerifier} from "firebase/auth";
import {firebaseAuth} from "./firebase";

let recaptchaVerifier = null

function initRecaptcha() {
    if (!recaptchaVerifier) {
        recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, "request-otp", {
            'size': "invisible",
        })
    }
}

export const getRecaptchaVerifier = () => recaptchaVerifier;

const App = () => {
    useEffect(() => {
        initRecaptcha()
    }, []);

    return (
        <>
            <Admin
                disableTelemetry
                title="Nekster Admin"
                loginPage={LoginPage}
                layout={MainLayout}
                dashboard={Dashboard}
                customRoutes={customRoutes}
                authProvider={authProvider}
                dataProvider={dataProvider}
                customReducers={{
                    companyData: companyLookup,
                    reportsData: availableReports,
                    companyReportData: companyReport,
                    customerPriceList: customerPriceList
                }}>
                {permissions => [
                    <Resource name="user_documents_2" icon={DocumentIcon} options={{label: 'Documents'}}
                              list={DocumentList} edit={DocumentEdit}/>,
                    <Resource name="investments" list={InvestmentList} edit={InvestmentEdit}
                              create={InvestmentCreate}/>,
                    <Resource name="investmentCompanies" icon={CompanyIcon} options={{label: 'Companies'}}
                              list={InvestmentCompanyList} edit={InvestmentCompanyEdit}
                              create={InvestmentCompanyCreate}/>,
                    <Resource name="taxes" icon={TaxIcon} list={TaxList}/>,
                    <Resource name="customer" icon={UserIcon} options={{label: 'Users'}} list={CustomerList}
                              edit={CustomerEdit}/>,
                    <Resource name="transactions" icon={TransactionIcon} list={TransactionList} edit={TransactionEdit}
                              create={TransactionCreate}/>,
                    <Resource name="interests_by_date" options={{label: 'Interests by date'}}
                              list={InterestsByDateList}/>,
                    <Resource name="investments_by_date" options={{label: 'Investments by date'}}
                              list={InvestmentsByDateList}/>,
                    /*<Resource name="admin_users" icon={AdminUserIcon} list={AdminUsersList} edit={permissions.includes('ADMIN_USER_WRITE') ? AdminUserEdit : null} create={permissions.includes('ADMIN_USER_WRITE') ? AdminUserCreate : null}/>,*/
                    <Resource name="notification_queue" icon={EmailIcon} options={{label: 'Notifications'}}
                              list={NotificationsList}/>,
                    <Resource name="test_users" options={{label: 'Test users'}} list={TestUserList}
                              create={TestUserCreate}/>,
                    <Resource name="countries"/>,
                    <Resource name="user_investments"/>,
                    <Resource name="user_funds_history"/>
                ]}
            </Admin>
            <div id="request-otp"></div>
        </>
    );
}

export default App;
