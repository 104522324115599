import React, {useEffect, useState} from 'react';
import {httpClient} from "../helpers/helpers";
import * as Config from "../config";
import {useLogout, useRefresh} from "react-admin";
import {StatsList} from "./StatsList";

const columns = [
    { id: 'year', label: 'Year', minWidth: 40 },
    { id: 'month', label: 'Month', minWidth: 40 },
    { id: 'monthName', label: 'Month name', minWidth: 70 },
    { id: 'added', label: 'Users added', minWidth: 70, align: 'right', format: (value) => value.toLocaleString('en-US') },
    { id: 'total', label: 'Total users', minWidth: 70, align: 'right', format: (value) => value.toFixed(0) },
    { id: 'investorCount', label: 'Investor count', minWidth: 70, align: 'right', format: (value) => value.toFixed(0) },
    { id: 'averageFunds', label: 'Average funds', minWidth: 70, align: 'right', format: (value) => value.toFixed(2) },
];

async function investorCountStats() {
    return httpClient(`${Config.ADMIN_API_URL}/investor_count_stats`
    ).then(data => {
        switch (data.status) {
            case 401: throw new Error("Session expired!");
            default: return data.json.monthly.reverse()
        }
    }).catch(error => {
        return error;
    })
}

export const InvestorCountList = () => {
    const refresh = useRefresh();
    const logout = useLogout();

    const [items, setItems] = useState(undefined)

    useEffect(() => {
        if (items === undefined) {
            investorCountStats()
                .then(d => {
                    if (d instanceof Error) throw d;
                    setItems(d);
                    refresh();
                })
                .catch(e => {
                    if (e.status === 401) {
                        logout();
                    }
                    console.log(e.body || e);
                })
        }
    }, [items, refresh, logout]);

    return (
        <StatsList columns={columns} items={items} />
    );
}