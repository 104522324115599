import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export const StatsList = ({columns, items, hidePagination = false, isPercent = false, titleBar = null, onRowClick = null}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleRowClick = rowNo => {
        onRowClick?.(rowNo)
    }

    // (Potentially) useful links:
    // - https://material-ui.com/components/tables/#dense-table
    // - https://material-ui.com/components/tables/#fixed-header
    // - https://material-ui.com/components/tables/#custom-pagination-options
    // - https://material-ui.com/components/tables/#custom-pagination-actions
    return (
        <Paper className={classes.root}>
            {!!titleBar && titleBar}
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items && items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => handleRowClick(index)}>
                                {columns.map((column, index) => {
                                    const value = row[column.id];
                                    const unit = isPercent ? '%' : '';
                                    return (
                                        <StatsListTableCell key={index} column={column} value={value} unit={unit} />
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!hidePagination && <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={items?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Paper>
    );
}

const StatsListTableCell = ({ column, value, unit }) => {
    return (
        <TableCell align={column.align}>
            {!column.format && value}
            {column.format && typeof value === 'number' && `${column.format(value)}${unit}`}
            {column.format && typeof value === 'boolean' && `${column.format(value)}${unit}`}
        </TableCell>
    )
}