import React, {useEffect, useState} from 'react';
import {decimalNumberFormatter, httpClient} from "../helpers/helpers";
import * as Config from "../config";
import {useLogout, useRefresh} from "react-admin";
import {StatsList} from "./StatsList";

const columns = [
    { id: 'dateFormatted', label: 'date', minWidth: 40 },
    { id: 'deposits', label: 'Deposits', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'withdrawals', label: 'Withdrawals', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'fundsDiff', label: 'Diff', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'interests', label: 'Interests', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'taxes', label: 'Tax', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'totalFunds', label: 'Total funds', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'invested', label: 'Invested', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'availableFunds', label: 'Available funds', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'investedRatio', label: '% invested', minWidth: 70, align: 'right', format: (value) => (100 * value).toFixed(0) },
];

async function dailySettlementsHistory() {
    return httpClient(`${Config.ADMIN_API_URL}/daily_settlements`
    ).then(data => {
        switch (data.status) {
            case 401: throw new Error("Session expired!");
            default: return data.json
        }
    }).catch(error => {
        return error;
    })
}

export const DailySettlementsList = () => {
    const refresh = useRefresh();
    const logout = useLogout();

    const [items, setItems] = useState(undefined)

    useEffect(() => {
        if (items === undefined) {
            dailySettlementsHistory()
                .then(d => {
                    if (d instanceof Error) throw d;
                    setItems(d);
                    refresh();
                })
                .catch(e => {
                    if (e.status === 401) {
                        logout();
                    }
                    console.log(e.body || e);
                })
        }
    }, [items, refresh, logout]);

    return (
        <StatsList columns={columns} items={items} />
    );
}