import React, {useEffect, useState} from "react";
import {classNames, parseNumberString} from "../helpers/helpers";
import {daysBetween} from "../utils";
import {DownloadIcon, LinkIcon, XIcon} from "@heroicons/react/solid";
import {PROMISSORY_NOTE_STATUS_KEY} from "./consts";

export function DataTable({
                              id,
                              rows,
                              originalRowMap,
                              updateRow,
                              deleteRow,
                              status,
                              infoCosts,
                              fetchInfoCosts,
                              onMaxClick,
                              minAgreedPaymentDate,
                              mep,
                              download
                          }) {
    const limit = 20;
    const [pagination, setPagination] = useState(false)
    const [page, setPage] = useState(0)

    useEffect(() => {
        setPagination(rows?.length > limit)
    }, [rows])

    return (
        <div className="bg-white divide-y divide-gray-200">
            <div className="divide-y divide-gray-200">
                {rows.map((row, index) => (
                    <div key={row.id} className={classNames(
                        (index >= page * limit) && (index < (page + 1) * limit) ? "" : "hidden"
                    )}>
                        <InvoiceRow row={row} originalRow={originalRowMap[row.id] || null} updateRow={updateRow}
                                    deleteRow={deleteRow} promissoryNoteId={id}
                                    status={status} onMaxClick={onMaxClick}
                                    minAgreedPaymentDate={minAgreedPaymentDate}
                                    download={download}
                                    mep={mep}/>
                    </div>
                ))}
            </div>
            {rows.length === 0 && (
                <div
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-400 text-center">
                    <span>No entries</span>
                </div>
            )}

            {infoCosts && infoCosts.length > 0 ? (
                <div className="py-3">
                    {infoCosts.map((infoCost, index) => (
                            <div
                                key={index}
                                className="flex">
                                <div className="flex-1 p-2 px-4 w-full">{index === 0 && (
                                    <span
                                        onClick={() => fetchInfoCosts()}
                                        className="text-sm cursor-pointer underline text-primary-400 hover:text-primary-500">
                                        Recalculate costs
                                    </span>
                                )}</div>
                                <div className="flex-1 p-2 w-full"/>
                                <div className="flex-1 p-2 w-full"/>
                                {id && (
                                    <>
                                        <div className="flex-1 p-2 w-full"/>
                                        <div className="flex-1 p-2 w-full"/>
                                    </>
                                )}
                                <div className="flex-1 p-2 pr-5 border-r-2 border-transparent w-full text-sm text-right">
                                    {infoCost[0]}
                                </div>
                                <div className="flex-1 p-2 pl-5 border-l-2 border-transparent w-full text-sm">
                                    {infoCost[1]}
                                </div>
                            </div>
                        )
                    )}
                </div>
            ) : !id ? (
                <div className="py-3">
                    <div className="flex">
                        <div className="flex-1 p-2 px-4 w-full">
                            <span
                                onClick={() => fetchInfoCosts()}
                                className="text-sm cursor-pointer underline text-primary-400 hover:text-primary-500">
                                Calculate costs
                            </span>
                        </div>
                        <div className="flex-1 p-2 w-full"/>
                        <div className="flex-1 p-2 w-full"/>
                        {id && (
                            <>
                                <div className="flex-1 p-2 w-full"/>
                                <div className="flex-1 p-2 w-full"/>
                            </>
                        )}
                        <div className="flex-1 p-2 pr-5 border-r-2 border-transparent w-full text-sm text-right"/>
                        <div className="flex-1 p-2 pl-5 border-l-2 border-transparent w-full text-sm"/>
                    </div>
                </div>
            ) : null}

            <nav
                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
                aria-label="Pagination"
            >
                <div className="hidden py-2 sm:block">
                    <p className="text-sm text-gray-700">
                        Showing <span
                        className="font-medium">{rows.length > 0 ? (page * limit) + 1 : "0"}</span> to <span
                        className="font-medium">{rows.length > 0 ? ((page + 1) * limit) > rows.length ? rows.length : (page + 1) * limit : "0"}</span>
                        {' '}of{' '}
                        <span className="font-medium">{rows.length || "0"}</span> results total
                    </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                    <button
                        type="button"
                        disabled={!(pagination && page > 0)}
                        onClick={() => setPage(page - 1)}
                        className={classNames(
                            "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                            (pagination && page > 0) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                        )}
                    >
                        Previous
                    </button>
                    <button
                        type="button"
                        disabled={!(pagination && ((page + 1) * limit) - 1 < rows.length)}
                        onClick={() => setPage(page + 1)}
                        className={classNames(
                            "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                            (pagination && ((page + 1) * limit) - 1 < rows.length) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                        )}
                    >
                        Next
                    </button>
                </div>
            </nav>
        </div>
    )
}

function InvoiceRow({
                        promissoryNoteId,
                        row,
                        status,
                        originalRow,
                        updateRow,
                        deleteRow,
                        download,
                        onMaxClick,
                        mep
                    }) {
    const canEdit = status === PROMISSORY_NOTE_STATUS_KEY.PENDING_APPROVAL || status === PROMISSORY_NOTE_STATUS_KEY.DRAFT;

    const [number, setNumber] = useState(row?.number || "")
    const [isProforma, setIsProforma] = useState(row?.isProforma || false)
    const [date, setDate] = useState(row?.date || "")
    const [dueDate, setDueDate] = useState(row?.dueDate || "")
    const [extendedPaymentDate, setAgreedPaymentDate] = useState(row?.extendedPaymentDate || "")
    const [amount, setAmount] = useState(row?.amount || "")
    const [paidAmount, setPaidAmount] = useState("")
    const [paidDate, setPaidDate] = useState("")
    const [maxAgreedPaymentDate, setMaxAgreedPaymentDate] = useState(null)

    const paidUp = !!originalRow?.repaidDate

    useEffect(() => {
        setPaidAmount("")
        setPaidDate("")
    }, [originalRow]);

    useEffect(() => {
        if (typeof maxAgreedPaymentDate === "string" && typeof extendedPaymentDate === "string") {
            // Let's check if extendedPaymentDate is valid (below maxAgreedPaymentDate)
            const maxDate = new Date(maxAgreedPaymentDate),
                setDate = new Date(extendedPaymentDate),
                days = daysBetween(setDate, maxDate);

            if (days < 0) {
                setAgreedPaymentDate(maxAgreedPaymentDate)
                return
            }
        }
        setMaxAgreedPaymentDate(calcMaxAgreedPaymentDate(date, mep))
    }, [maxAgreedPaymentDate])

    useEffect(() => {
        setMaxAgreedPaymentDate(calcMaxAgreedPaymentDate(date, mep))
    }, [date, mep])

    useEffect(() => {
        if (onMaxClick > 0) paidAmountMax()
    }, [onMaxClick])

    /*useEffect(() => {
        if (paidAmount && !paidDate) {
            const now = new Date(),
                _paidDateValue = now.toLocaleDateString('en-CA')
            updateValue("paidDate", setPaidDate, _paidDateValue)
        }
    }, [paidAmount])*/

    useEffect(() => {
        if (row?._paid?.paidAmount && row._paid?.paidDate) {
            updateValue("paidDate", setPaidDate, row._paid?.paidDate)
            updateValue("paidAmount", setPaidAmount, "" + row._paid?.paidAmount, row._paid?.paidAmount)
        }
    }, [row?._paid])

    useEffect(() => {
        if (row?.paidDate) {
            setPaidDate(row.paidDate)
        }
    }, [row?.paidDate])

    function updateValue(fieldName, valueSetter, value, parsedValue) {
        if (!row) return
        const updatedValue = parsedValue || value
        updateRow && updateRow(row.id, row => ({
            ...row,
            fictional: row.fictional || false,
            changed: row.changed || (typeof originalRow === "object" ? originalRow[fieldName] !== updatedValue : true), // this has got to be true on any change as we're not comparing all fields with original row
            [fieldName]: updatedValue
        }))
        valueSetter(value)
    }

    function onValueChange(e) {
        if (typeof originalRow !== "object") return
        const {name, value} = e.target;
        switch (name) {
            case "number":
                return updateValue(name, setNumber, value)
            case "isProforma":
                return updateValue(name, setIsProforma, !isProforma)
            /*case "status":
                return updateValue(name, setStatus, value)*/
            case "date":
                return updateValue(name, setDate, value)
            case "dueDate":
                return updateValue(name, setDueDate, value)
            case "extendedPaymentDate":
                return updateValue(name, setAgreedPaymentDate, value)
            case "amount":
                return updateValue(name, setAmount, value, parseNumberString(value) || 0)
            case "paidAmount":
                const max = originalRow.amount - (originalRow.paidAmount || 0),
                    _value = parseNumberString(value) > max ? max : value;
                return updateValue(name, setPaidAmount, _value, parseNumberString(_value) || 0)
            case "paidDate":
                return updateValue(name, setPaidDate, value)
        }
    }

    function calcMaxAgreedPaymentDate(date, mep) {
        if (typeof date === "string" && date && mep > 0) {
            const _d = new Date(date)
            _d.setDate(_d.getDate() + mep)
            //console.log("Setting max agreed payment date", date, mep, _d.toLocaleDateString('en-CA'))
            return _d.toLocaleDateString('en-CA')
        }
        return null
    }

    function paidAmountMax() {
        if (typeof originalRow !== "object") return
        const _max = (originalRow.amount - (originalRow.paidAmount || 0)) || 0
        //console.log("MAX", _max)
        if (_max > 0) {
            const now = new Date(),
                _paidDateValue = now.toLocaleDateString('en-CA')
            updateValue("paidDate", setPaidDate, _paidDateValue)
            updateValue("paidAmount", setPaidAmount, "" + _max, _max)
        }
    }

    return (
        <div className="relative">
            <div
                className="ml-1 grid grid-cols-table">
                <div className="flex">
                    <div className="flex-1 flex items-center p-2 w-full">
                        <div className="flex h-6 items-center mr-2">
                            <input
                                className={classNames(
                                    "h-4 w-4 rounded border-gray-300",
                                    (!canEdit || !!originalRow) ? "bg-gray-100 text-gray-500" : "text-primary-600 focus:ring-primary-600"
                                )}
                                title={"Proforma"}
                                name="isProforma"
                                type="checkbox"
                                disabled={!canEdit || !!originalRow}
                                onChange={onValueChange}
                                checked={isProforma}
                            />
                        </div>
                        <input
                            className={classNames(
                                "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                (!canEdit || !!originalRow) ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                            )}
                            type="text"
                            disabled={!canEdit || !!originalRow}
                            name="number"
                            onChange={onValueChange}
                            value={number}
                        />
                    </div>
                    <div className="flex-1 p-2 w-full">
                        <input
                            className={classNames(
                                "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                (!canEdit || !!originalRow) ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                            )}
                            type="date"
                            disabled={!canEdit || !!originalRow}
                            name="date"
                            onChange={onValueChange}
                            value={date}
                        />
                    </div>
                    <div className="flex-1 p-2 w-full">
                        <input
                            className={classNames(
                                "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                (!canEdit || !!originalRow) ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                            )}
                            type="date"
                            disabled={!canEdit || !!originalRow}
                            name="dueDate"
                            onChange={onValueChange}
                            value={dueDate}
                        />
                    </div>
                    <div className="flex-1 p-2 w-full">
                        <input
                            className={classNames(
                                "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm bg-gray-50",
                            )}
                            type="date"
                            max={maxAgreedPaymentDate}
                            name="extendedPaymentDate"
                            onChange={onValueChange}
                            value={extendedPaymentDate}
                        />
                    </div>
                    <div className="flex-1 p-2 w-full">
                        <input
                            className={classNames(
                                "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                            )}
                            type="text"
                            disabled={!canEdit}
                            name="amount"
                            onChange={onValueChange}
                            value={amount}
                        />
                    </div>
                    {(status === PROMISSORY_NOTE_STATUS_KEY.FINANCING_IN_PROGRESS || status === PROMISSORY_NOTE_STATUS_KEY.FINANCED) && (
                        <>
                            <div className="flex-1 p-2 w-full">
                                <input
                                    className={classNames(
                                        "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                        paidUp ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                                    )}
                                    disabled={paidUp}
                                    type="date"
                                    name="paidDate"
                                    onChange={onValueChange}
                                    value={paidUp ? originalRow?.repaidDate : paidDate}
                                />
                            </div>
                            <div className="flex-1 p-2 w-full relative">
                                <input
                                    className={classNames(
                                        "flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                                        paidUp ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                                    )}
                                    type="text"
                                    placeholder={originalRow?.paidAmount || "0"}
                                    name="paidAmount"
                                    disabled={paidUp}
                                    onChange={onValueChange}
                                    value={paidAmount}
                                />
                                {!paidUp && (
                                    <button type="button"
                                            className="absolute top-4 right-4 bg-gray-200 text-gray-600 px-2 py-0.5 rounded-md text-xs"
                                            onClick={paidAmountMax}>MAX
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="flex items-center justify-end px-4">
                    {row.fileId && (
                        <button
                            type="button"
                            onClick={() => download(row.fileId, `invoice-${number}.pdf`)}
                            className={classNames(
                                "ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                                "text-gray-500 bg-gray-200 hover:bg-gray-300"
                            )}
                        ><DownloadIcon className="h-3 w-3" aria-hidden="true"/></button>
                    )}
                    {canEdit && (
                        <button
                            type="button"
                            disabled={!canEdit}
                            onClick={() => deleteRow(row.id)}
                            className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-gray-500 bg-gray-200 hover:bg-gray-300"
                        >
                            <XIcon className="h-3 w-3" aria-hidden="true"/>
                        </button>
                    )}
                    {!canEdit && (
                        <>
                            <a
                                href={"#/promissory-notes/" + promissoryNoteId + "/invoices/" + row.id}
                                className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-gray-500 bg-gray-200 hover:bg-gray-300"
                            >
                                <LinkIcon className="h-3 w-3" aria-hidden="true"/>
                            </a>
                        </>
                    )}
                </div>
            </div>
            {row.changed && (
                <div className={classNames(
                    "absolute top-0 left-0 bottom-0 block w-1",
                    row.fictional ? "bg-green-300" : "bg-blue-300"
                )}/>
            )}
        </div>
    )
}
