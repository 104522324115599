import React from "react";
import {Layout, AppBar, UserMenu, MenuItemLink} from 'react-admin';
import MainMenu from "./MainMenu";
import UserIcon from '@material-ui/icons/Person';

const MainLayout = props => <Layout {...props} menu={MainMenu} appBar={CustomAppBar} />

const CustomAppBar = props => <AppBar {...props} userMenu={<CustomUserMenu />} />

const CustomUserMenu = props => (
    <UserMenu {...props}>
        <MenuItemLink
            to="/profile"
            primaryText="Profile"
            leftIcon={<UserIcon />}
        />
    </UserMenu>
);

export default MainLayout;
