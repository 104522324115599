import React, {useCallback, useEffect, useState} from 'react';
import {useField, useForm} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {List, Edit, Datagrid, TextField, TabbedForm, FormTab, Toolbar, SaveButton, FunctionField, BooleanInput, NumberInput, TextInput, SelectInput, Labeled} from 'react-admin';
import {PartnerSinceInput, CreditRankingInput, CompanySizeInput, EmployeeCountInput, TotalIncomeInput, LanguageInput} from "../components/selectInputs";
import BankAccountsList from "./BankAccountsList";
import Spacer from "../components/Spacer";
import {Box, Divider, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {AjpesData} from "./AjpesData";
import SearchFilter from "../components/searchFilter";
import OptionalNumberInput from "../components/OptionalNumberInput";
import useInputStyles from "../components/InputStyles";
import {reportsAction, updateAvailableReportsData} from "../actions/reportsActions";
import {customerPriceListAction, updateCustomerPriceListData} from "../actions/customerPriceListAction";
import {decimalNumberFormatter, properDecimalNumber} from "../helpers/helpers";

const useStyles = makeStyles({
    features: {
        flexGrow: 1,
    },
});

export const InvestmentCompanyList = props => (
    <List {...props} filters={<SearchFilter />} title="Companies" bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="edit">
            <FunctionField render={record => record.shortName || record.name || ''} label="Name" sortBy="name" />
            <TextField source="address" />
            <TextField source="city" />
            <TextField source="country.name" label="Country" />
            <TextField source="taxNumber" />
            <TextField source="partnerSince" />
        </Datagrid>
    </List>
);

const InvestmentCompanyEditTitle = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>;
};

const InvestmentCompanyEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const AvailableReports = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateAvailableReportsData({}));
        reportsAction(dispatch, props.record.id);
    }, [props.record.id, dispatch]);

    return (<></>);
}

const CustomerPriceList = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateCustomerPriceListData({}));
        customerPriceListAction(dispatch, props.record.id);
    }, [props.record.id, dispatch]);

    return (<></>);
}

export const InvestmentCompanyEdit = props => {
    const classes = useStyles();
    const availableCompanyReports = useSelector(state => state.reportsData.availableCompanyReports)

    const transform = data => {
        data.data.financialData.totalIncome = properDecimalNumber(data.data.financialData.totalIncome)
        data.data.financialData.scores = null
        return data
    }

    const save = (data, redirectTo, options) => {
        console.log("HERE")
        return Promise.resolve(data)
    }

    const onEditSuccess = response => {
        console.log("Edit success", response)
        // console.log(data)
    }

    return (
        <Edit {...props} title={<InvestmentCompanyEditTitle/>} transform={transform}>
            <TabbedForm toolbar={<InvestmentCompanyEditToolbar/>} redirect={false} variant="outlined">
                <FormTab label="Company data">
                    <AvailableReports {...props} />
                    <CustomerPriceList {...props} />

                    <TextField disabled source="taxNumber" variant="inherit"/>
                    <FunctionField render={record => record.shortName || record.name || ''} label="Name" variant="inherit"/>
                    <TextField disabled source="address" variant="inherit"/>
                    <TextField disabled source="city" variant="inherit"/>
                    <TextField disabled source="country.name" label="Country" variant="inherit"/>

                    <PartnerSinceInput/>
                    <BooleanInput source="data.registered" label="Registered on platform" helperText={false}/>
                    <BankAccountsList />
                    <Spacer />
                    <NoShadowInput source="data.director" label="Director" />
                    <CompanySizeInput/>
                    <EmployeeCountInput/>

                    <Features {...props} />
                    <Emails {...props} />

                    <LanguageInput source="data.language" label="Default language"/>
                </FormTab>
                <FormTab label="Financial data" path="financial">
                    <OptionalNumberInput decimalPlaces={0} source="data.financialData.failureScore" label="Failure score" onCanEdit={(autoData) => autoData?.hasFailureScoreData}/>
                    <OptionalNumberInput decimalPlaces={0} source="data.financialData.creditRating" label="Credit rating"onCanEdit={(autoData) => autoData?.hasCreditRatingData}/>

                    <Scores {...props} />

                    <Box fullWidth sx={{ m: 1 }}>
                        <Divider variant="middle"/>
                    </Box>

                    <SelectInput source="data.financialData.insuranceCreditScore" label="Insurance credit score" helperText={false} choices={[
                        {id: '1', name: '1'},
                        {id: '2', name: '2'},
                        {id: '3', name: '3'},
                        {id: '4', name: '4'},
                        {id: '5', name: '5'},
                        {id: '6', name: '6'},
                        {id: '7', name: '7'},
                        {id: '8', name: '8'},
                        {id: '9', name: '9'},
                        {id: '10', name: '10'},
                    ]}/>

                    <OptionalNumberInput source="data.financialData.totalIncome" label="Total income" onCanEdit={(autoData) => autoData?.hasTotalIncomeData}/>

                    <TotalIncomeInput/>
                    <CreditRankingInput/>

                    <span style={{fontWeight: 'bold'}}>Reverse factoring limits</span>
                    <div style={{width: "100%"}}>
                        <NoShadowNumberInput source="data.financialData.limits.reverseFactoringLimit.limit" label="Limit" {...props}/>&nbsp;&nbsp;&nbsp;
                        <NoShadowNumberInput source="data.financialData.limits.reverseFactoringLimit.availableLimit" label="Available limit" {...props}/>&nbsp;&nbsp;&nbsp;
                        <NoShadowNumberInput source="data.financialData.limits.reverseFactoringLimit.maxExtensionPeriod" label="Max extension period" {...props}/>
                    </div>

                    <span style={{fontWeight: "bold",}}>Factoring limits</span>
                    <div style={{width: "100%"}}>
                        <NoShadowNumberInput source="data.financialData.limits.factoringLimit.limit" label="Limit" {...props}/>&nbsp;&nbsp;&nbsp;
                        <NoShadowNumberInput source="data.financialData.limits.factoringLimit.availableLimit" label="Available limit" {...props}/>&nbsp;&nbsp;&nbsp;
                        <NoShadowNumberInput source="data.financialData.limits.factoringLimit.maxExtensionPeriod" label="Max extension period" {...props}/>
                    </div>

                    <span style={{fontWeight: "bold",}}>Leasing limits</span>
                    <div style={{width: "100%"}}>
                        <NoShadowNumberInput source="data.financialData.limits.leasingLimit.limit" label="Limit" {...props}/>&nbsp;&nbsp;&nbsp;
                        <NoShadowNumberInput source="data.financialData.limits.leasingLimit.availableLimit" label="Available limit" {...props}/>&nbsp;&nbsp;&nbsp;
                        <NoShadowNumberInput source="data.financialData.limits.leasingLimit.maxExtensionPeriod" label="Max extension period" {...props}/>
                    </div>

                    <MaxFinancingLimit />

                    <NoShadowNumberInput source="data.financialData.euribor" label="Euribor"/>

                    <NoShadowNumberInput source="data.financialData.multiplier" label="Multiplier"/>
                </FormTab>
                <FormTab label="Price list" path="pricelist">
                    <PriceList />
                </FormTab>
                {availableCompanyReports && <FormTab label="Ajpes Data" path="ajpes">
                    <AjpesData availableCompanyReports={availableCompanyReports} />
                </FormTab>}
            </TabbedForm>
        </Edit>
    );
}

const Features = ({...props}) => {
    const classes = useStyles();

    return (
        <Labeled label="Features">
            <Grid container className={classes.features} spacing={3}>
                <Grid item><BooleanInput source="data.supplierFinancing" label="Supplier financing" helperText={false}/></Grid>
                <Grid item><BooleanInput source="data.buyerFinancing" label="Buyer financing" helperText={false}/></Grid>
                <Grid item><BooleanInput source="data.supplyChainFinancing" label="Supply chain financing" helperText={false}/></Grid>
            </Grid>
        </Labeled>
    )
}

const Emails = ({...props}) => {
    const classes = useStyles();

    return (
        <Labeled label="Emails">
            <Grid container className={classes.features} spacing={3}>
                <Grid item><NoShadowInput source="data.emailInvoices" label="Email invoices" {...props}/></Grid>
                <Grid item><NoShadowInput source="data.emailDocuments" label="Email documents" {...props}/></Grid>
            </Grid>
        </Labeled>
    )
}

const MaxFinancingLimit = () => {
    const source = 'data.financialData.limits.maxFinancingLimit'
    const totalIncomeSource = 'data.financialData.totalIncome'

    const {input: {value: ratio}} = useField(source)
    const {input: {value: totalIncome}} = useField(totalIncomeSource)

    const formatValue = useCallback(() => {
        const income = properDecimalNumber(totalIncome)
        if (!ratio || !income) return ''
        return decimalNumberFormatter(ratio * income, 0)
    }, [ratio, totalIncome])

    return (
        <div style={{width: "100%", verticalAlign: "middle"}}>
            <SelectInput variant="outlined" source={source} label="Max financing limit" helperText={false} choices={[
                {id: '0.01', name: '1%'},
                {id: '0.02', name: '2%'},
                {id: '0.03', name: '3%'},
                {id: '0.04', name: '4%'},
                {id: '0.05', name: '5%'},
                {id: '0.06', name: '6%'},
                {id: '0.07', name: '7%'},
                {id: '0.08', name: '8%'},
                {id: '0.09', name: '9%'},
                {id: '0.1', name: '10%'},
            ]}/>
            &nbsp;&nbsp;&nbsp;
            <span style={{marginTop: "8px", height: "48px", display: "inline-flex", alignItems: "center"}}>{formatValue()}</span>
        </div>
    )
}

const Scores = ({record, ...props}) => {
    const editableScore = record?.autoData?.hasFailureScoreData !== true || record?.autoData?.hasCreditRatingData !== true

    return (
        <>
            <span style={{fontWeight: "bold"}}>Company score</span>
            <div style={{width: '100%'}}>
                <NoShadowNumberInput source="data.financialData.neksterScore" label="Nekster score" disabled={!editableScore} {...props}/>
                &nbsp;&nbsp;&nbsp;{!editableScore && <NoShadowNumberInput source="data.financialData.calculatedScore" label="Calculated score" disabled {...props}/>}
                &nbsp;&nbsp;&nbsp;{!editableScore && <NoShadowNumberInput source="data.financialData.scoreMultiplier" label="Score multiplier" {...props}/>}
            </div>
            {record?.data?.parentCompany && <GroupScores record={record} {...props}/>}
        </>
    )
}

const GroupScores = ({record, ...props}) => {
    return (
        <>
            <span style={{fontWeight: "bold"}}>Group score</span>
            <div style={{width: '100%', border: '1px solid #ccc', borderRadius: '0.5em', padding: '0.5em'}}>
                <TextInput variant={props.variant} source="data.parentCompany" label="Parent company" fullWidth helperText={false} disabled/>
                <div style={{width: '100%'}}>
                    <NoShadowNumberInput source="data.financialData.manualGroupScore" label="Manual group score" {...props}/>&nbsp;&nbsp;&nbsp;
                    <NoShadowNumberInput source="data.financialData.calculatedGroupScore" label="Calculated group score" disabled {...props}/>&nbsp;&nbsp;&nbsp;
                    <NoShadowNumberInput source="data.financialData.groupScoreMultiplier" label="Group score multiplier" {...props}/>&nbsp;&nbsp;&nbsp;
                    <NoShadowNumberInput source="data.financialData.parentFailureScore" label="Parent failure score" disabled {...props}/>&nbsp;&nbsp;&nbsp;
                    <NoShadowNumberInput source="data.financialData.parentCreditRating" label="Parent credit rating" disabled {...props}/>
                </div>
            </div>
        </>
    )
}

const NoShadowNumberInput = props => {
    const classes = useInputStyles()

    return (
        <NumberInput
            variant="outlined"
            source={props.source}
            label={props.label}
            disabled={props.disabled}
            helperText={false}
            InputProps={{ classes: { input: classes.input }}} />
    )
}

const NoShadowInput = props => {
    const classes = useInputStyles()

    return (
        <TextInput
            variant="outlined"
            source={props.source}
            label={props.label}
            disabled={props.disabled}
            helperText={false}
            InputProps={{ classes: { input: classes.input }}}/>
    )
}

const PriceList = () => {
    const form = useForm()
    // console.log(form.getState().initialValues?.data)

    const priceList = useSelector(state => state.customerPriceList.prices)
    const [reverseFactoring, setReverseFactoring] = useState(null)
    const [factoring, setFactoring] = useState(null)

    useEffect(() => {
        if (!Array.isArray(priceList) || priceList.length === 0) return

        updateInitialFormValues(form, priceList)

        setReverseFactoring(form.getState().values?.priceList?.reverseFactoring)
        setFactoring(form.getState().values?.priceList?.factoring)
    }, [priceList])

    const updateInitialFormValues = (form, prices) => {
        const currentValues = form.getState().values

        const updatedInitialValues = {
            ...currentValues,
            priceList: {
                reverseFactoring: prices.find(p => p.type === 'RF'),
                factoring: priceList.filter(p => p.type === 'F')
            }
        }

        form.reset(updatedInitialValues)
    }

    return (
        <>
            {reverseFactoring && <>
                <span style={{fontWeight: 'bold'}}>Reverse factoring price</span>

                <div style={{width: "100%"}}>
                    <NoShadowNumberInput source="priceList.reverseFactoring.insurance" label="Insurance" /><HorizontalSpacer />
                    <NoShadowNumberInput source="priceList.reverseFactoring.interestRate" label="Interest rate" /><HorizontalSpacer />
                    <NoShadowNumberInput source="priceList.reverseFactoring.euriborRate" label="Euribor rate" /><HorizontalSpacer />
                    <NoShadowNumberInput source="priceList.reverseFactoring.processingFee" label="Processing fee" /><HorizontalSpacer />
                    <NoShadowNumberInput source="priceList.reverseFactoring.additionalCosts" label="Additional costs" />
                </div>
            </>}

            {Array.isArray(factoring) && factoring.length > 0 && <>
                <span style={{fontWeight: "bold",}}>Factoring prices</span>
                <div style={{width: "100%"}}>
                    {factoring && factoring.map((item, index) =>
                        <div key={index}>
                            <NoShadowInput disabled source={`priceList.factoring[${index}].partnerName`} label="Partner company" /><HorizontalSpacer />
                            <NoShadowNumberInput source={`priceList.factoring[${index}].insurance`} label="Insurance" /><HorizontalSpacer />
                            <NoShadowNumberInput source={`priceList.factoring[${index}].interestRate`} label="Interest rate" /><HorizontalSpacer />
                            <NoShadowNumberInput source={`priceList.factoring[${index}].euriborRate`} label="Euribor rate" /><HorizontalSpacer />
                            <NoShadowNumberInput source={`priceList.factoring[${index}].processingFee`} label="Processing fee" /><HorizontalSpacer />
                            <NoShadowNumberInput source={`priceList.factoring[${index}].additionalCosts`} label="Additional costs" />
                        </div>
                    )}
                </div>
            </>}

            {!reverseFactoring && (!Array.isArray(factoring) || factoring.length === 0) &&
                <div>
                    No factoring prices yet
                </div>
            }
        </>
    )
}

const HorizontalSpacer = () => {
    return (
        <>
            &nbsp;&nbsp;&nbsp;
        </>
    )
}