import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { companyLookupAction } from "../actions/companyLookupAction";
import useInputStyles from "./InputStyles";

let taxNumber = '';

const SearchButton = ({ onSearch }) => {
    const classes = useInputStyles()

    // const dispatch = useDispatch();
    // const notify = useNotify();
    const [loading, setLoading] = useState(false);

    // const handleClick = () => {
    //     setLoading(true);
    //     dispatch(fetchStart()); // start the global loading indicator
    //     fetch(`${Config.API_URL}/companies?tax_no=${taxNumber}`, {
    //     //fetch(`${Config.API_URL}/companies?tax_no=53079973`, {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then(response => response.json())
    //         .then((data) => {
    //             dispatch({
    //                 type: COMPANY_LOOKUP,
    //                 payload: data
    //             });
    //             notify('Company fetched');
    //         })
    //         .catch((e) => {
    //             notify('Error: company not found', 'warning')
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //             dispatch(fetchEnd()); // stop the global loading indicator
    //         });
    // };

    return (
        <Button variant="contained" className={classes.search} onClick={onSearch} disabled={loading}>
            <SearchIcon />
        </Button>
    );
};

const TaxNumberLookup = props => {
    const classes = useInputStyles()
    const dispatch = useDispatch();

    const onTaxNumberChange = e => {
        taxNumber = e.nativeEvent.target.value;

        if (props.onTaxNumberChanged) {
            props.onTaxNumberChanged(e);
        }
    };

    const handleSearch = () => {
        companyLookupAction(dispatch, taxNumber);
    };

    return (
        <TextInput
            variant={props.variant}
            helperText={false}
            source={props.source}
            onChange={onTaxNumberChange}
            InputProps={{
                classes: {
                    input: classes.input,
                    adornedEnd: classes.endAdornment,
                },
                endAdornment: (
                    <SearchButton onSearch={handleSearch} />
                )
            }}/>
    );
};

export default TaxNumberLookup;