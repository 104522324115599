import React, {useEffect, useState} from "react";
import {useField} from "react-final-form";
import {TextField} from "@material-ui/core";
import AmountInput from "./AmountInput";
import useInputStyles from "./InputStyles";
import {decimalNumberFormatter, properDecimalNumber} from "../helpers/helpers";

const OptionalNumberInput = ({decimalPlaces = 2, onCanEdit = null, ...props}) => {
    const classes = useInputStyles()
    const {input:{value: fieldValue}} = useField(props.source)
    const [value, setValue] = useState('') // This is for read-only value

    useEffect(() => {
        let newValue = decimalNumberFormatter(properDecimalNumber(fieldValue), 0)
        if (fieldValue === '') newValue = ''
        setValue(newValue)
    }, [fieldValue])

    return (
        <>
            {onCanEdit?.(props.record?.autoData) !== true
                ? <AmountInput variant={props.variant} source={props.source} label={props.label} maxDecimals={decimalPlaces} />
                : <TextField className={classes.textField} label={props.label} helperText={false} value={value} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true, disableUnderline: true }}/>
            }
        </>
    )
}

export default OptionalNumberInput