import React from 'react'
import TableView from "../components/tableView";
import currency from "currency.js";

/*
    insurance: number
    interestRate: number
    processingFee: number
 */


const tableColumns = [
    {v: d => d.name, label: "Name"},
    {v: d => d.value, label: "Value"},
    {v: d => d.percentage, label: "Percentage"},
]

export default function FactoringPricingSummarySection({factoringPricing, total}) {

    const processingFee = currency(factoringPricing.processingFee || 0, {precision: 10}).add(factoringPricing.additionalCosts || 0)

    const rows = [
        {
            name: 'Insurance',
            value: factoringPricing.insurance,
            percentage: currency(currency(factoringPricing.insurance, {precision: 10}).multiply(100).value, {precision: 2}).format({
                precision: 2,
                decimal: ",",
                symbol: ""
            }) + '%'
        },
        {
            name: 'Interest rate',
            value: factoringPricing.interestRate,
            percentage: currency(currency(factoringPricing.interestRate, {precision: 10}).multiply(100).value, {precision: 2}).format({
                decimal: ",",
                symbol: ""
            }) + '%'
        },
        {
            name: 'Euribor',
            value: factoringPricing.euriborRate,
            percentage: currency(currency(factoringPricing.euriborRate, {precision: 10}).multiply(100).value, {precision: 2}).format({
                decimal: ",",
                symbol: ""
            }) + '%'
        },
        {
            name: 'Processing fee',
            value: processingFee.value,
            percentage: currency(processingFee.multiply(100).value, {precision: 2}).format({
                decimal: ",",
                symbol: ""
            }) + '%'
        },
    ]

    return (
        <section className="bg-white shadow sm:rounded-lg overflow-hidden">

            <div className="px-4 py-5 sm:px-6 flex items-center">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Factoring pricing</h3>
                {/*<button
                    type="button"
                    onClick={() => alert("Not implemented yet. Refreshes all invoices in the database, gets fresh financing data (MEP), etc.")}
                    className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                    <RefreshIcon className="h-5 w-5" aria-hidden="true"/>
                </button>*/}
            </div>


            <TableView columns={tableColumns} data={rows} hasMore={false} selectionDisabled={true}
                       paginationDisabled={true}/>

        </section>
    )
}
