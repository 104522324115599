import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {blue} from "@material-ui/core/colors";

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

export const SimpleDialog = (props) => {
    const classes = useStyles();

    const { onClose, open, items } = props;

    const handleClose = () => {
        onClose('');
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    function getAccountNumber(bankAccount) {
        return bankAccount ? bankAccount.accountNumber : '';
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select user</DialogTitle>
            <List>
                {items.map((user, idx) => (
                    <ListItem button onClick={() => handleListItemClick(user)} key={idx}>
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <div>
                            <ListItemText primary={`${user.contact.firstName} ${user.contact.lastName} (${user.Id})`} />
                            <ListItemText primary={`${user.contact.email}`} />
                            <ListItemText primary={getAccountNumber(user.bankAccounts.find(i => i.main))} />
                        </div>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};