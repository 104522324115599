import React from 'react';
import {List, Datagrid, TextField, Create, SimpleForm, TextInput} from 'react-admin';

export const TestUserList = props => (
    <List {...props} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="Id" label="User ID" />
            <TextField source="contact.firstName" label="First name" />
            <TextField source="contact.lastName" label="Last name" />
            <TextField source="contact.email" label="Email" />
            <TextField source="contact.telephone" label="Phone" />
        </Datagrid>
    </List>
);

export const TestUserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="userId" label="User ID" />
        </SimpleForm>
    </Create>
);