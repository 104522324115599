import {sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {downloadFile} from "../utils";

export function downloadBilling(id, cb) {
    sharedFetch(`admin/finance/billing/${id}/download`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
        }
    })
        .then(response => response.blob())
        .then(blob => {
            downloadFile(blob, `bill-${id}.pdf`)
            //fetchData(id)
        })
        .finally(() => cb && cb())
}
