import * as React from "react";
import {Route} from 'react-router-dom';
import {PriceList} from "../priceList/PriceList";
import {PromissoryNoteList} from "../promissory-notes/list";
import {InvoiceList} from "../invoices/list";
import Invoice from "../invoices/entry";
import {LogList} from "../logs/list";
import PromissoryNote from "../promissory-notes/entry";
import {QuoteList} from "../quotes/list";
import Quote from "../quotes/entry";
import {SharedUsersList} from "../shared-users/list";
import SharedUser from "../shared-users/entry";
import {OrganizationsList} from "../organizations/list";
import Organization from "../organizations/entry";
import {PartnersList} from "../partners/list";
import Partner from "../partners/entry";
import {AdminUsersList} from "../admins/list";
import AdminUser from "../admins/entry";
import {UserProfile} from "../userProfile/UserProfile";
import {BillingList} from "../billing/list";
import {AdminActions} from "../admin-actions/list";

const routes = [
    <Route exact path="/quotes/create" component={Quote}/>,
    <Route exact path="/quotes/:id" component={Quote}/>,
    <Route exact path="/quotes" component={QuoteList}/>,
    <Route exact path="/promissory-notes/create" component={PromissoryNote}/>,
    <Route exact path="/promissory-notes/:id" component={PromissoryNote}/>,
    <Route exact path="/promissory-notes" component={PromissoryNoteList}/>,
    <Route exact path="/logs" component={LogList}/>,
    <Route exact path="/shared-users" component={SharedUsersList}/>,
    <Route exact path="/shared-users/:id" component={SharedUser}/>,
    <Route exact path="/admin-actions" component={AdminActions}/>,
    <Route exact path="/admins" component={AdminUsersList}/>,
    <Route exact path="/admins/:id" component={AdminUser}/>,
    <Route exact path="/organizations" component={OrganizationsList}/>,
    <Route exact path="/organizations/:id" component={Organization}/>,
    <Route exact path="/partners" component={PartnersList}/>,
    <Route exact path="/organizations/:organizationId/partners/:partnerId" component={Partner}/>,
    <Route exact path="/organizations/:organizationId/organization-partners/:partnerId" component={Partner}/>,
    <Route exact path="/billing" component={BillingList}/>,
    <Route exact path="/invoices" component={InvoiceList}/>,
    <Route exact path="/promissory-notes/:promissoryNoteId/invoices/:id" component={Invoice}/>,
    <Route exact path="/price_list" component={PriceList}/>,
    <Route exact path="/profile" component={UserProfile}/>,
];

export default routes;
