import {AVAILABLE_REPORTS, COMPANY_REPORT} from "../actions/reportsActions";

export const availableReports = (previousState = {}, { type, payload}) => {
    if (type === AVAILABLE_REPORTS) {
        const reportsData = {};
        if (Object.keys(payload).length) {
            reportsData['regNo'] = payload.regNo;
            reportsData['availableCompanyReports'] = payload.availableCompanyReports;
        }
        return reportsData;
    }
    return previousState;
};

export const companyReport = (previousState = {}, { type, payload}) => {
    if (type === COMPANY_REPORT) {
        const companyReportData = {};
        if (Object.keys(payload).length) {
            companyReportData['details'] = payload.data.details;
            companyReportData['yearlyReport'] = payload.data.yearlyReport;
        }
        return companyReportData;
    }
    return previousState;
};