import React from 'react';
import {List, Datagrid, TextField, NumberField, FunctionField} from 'react-admin';
import UtcDateField from "../components/utcDateField";
import SearchFilter from "../components/searchFilter";
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import {zeroPad} from "../helpers/helpers";

const amountOptions = { useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 };

const NoteIcon = ({ record = {}, source }) => (
    <div title={record[source]}>
        { record[source] ? (<NoteOutlinedIcon/>) : '' }
    </div>
)

export const TaxList = props => (
    <List {...props} filters={<SearchFilter />} sort={{ field: 'date', order: 'DESC' }} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <FunctionField render={record => record ? zeroPad(record.userDetails.reference.number, 6) : ''} label="#" />
            <TextField source="userDetails.humanFirstName" sortBy="user.details.firstname" label="First name" />
            <TextField source="userDetails.humanLastName" sortBy="user.details.lastname" label="Last name" />
            <TextField source="userDetails.taxNo" sortBy="user.details.taxNumber" label="Tax number" />
            <UtcDateField record={props.record} source="date" />
            <NumberField source="taxBaseAmount" options={amountOptions} label="Base amount" />
            <NumberField source="taxAmount" options={amountOptions} />
            <NoteIcon source="notes" />
        </Datagrid>
    </List>
);