import * as Config from '../config'
import {httpClient} from "../helpers/helpers"

export const CUSTOMER_PRICE_LIST = 'CUSTOMER_PRICE_LIST'

export const updateCustomerPriceListData = (priceListData) => ({
    type: CUSTOMER_PRICE_LIST,
    payload: priceListData,
})

export const customerPriceListAction = (dispatch, id) => {
    httpClient(`${Config.ADMIN_API_URL}/prices/customers/${id}`).then(res => {
        return res.json
    }).then(pricesData => {
        dispatch(updateCustomerPriceListData(pricesData))
    }).catch((err) => {
        console.log(err)

        dispatch(updateCustomerPriceListData({}))
    })
}
