import React from "react";
import {Filter, SearchInput} from "react-admin";

const SearchFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        {/*<ReferenceInput*/}
        {/*    source="category_id"*/}
        {/*    reference="categories"*/}
        {/*    sort={{ field: 'id', order: 'ASC' }}*/}
        {/*>*/}
        {/*    <SelectInput source="name" />*/}
        {/*</ReferenceInput>*/}
        {/*<NumberInput source="width_gte" />*/}
        {/*<NumberInput source="width_lte" />*/}
        {/*<NumberInput source="height_gte" />*/}
        {/*<NumberInput source="height_lte" />*/}
        {/*<QuickFilter*/}
        {/*    label="resources.products.fields.stock_lte"*/}
        {/*    source="stock_lte"*/}
        {/*    defaultValue={10}*/}
        {/*/>*/}
    </Filter>
);

export default SearchFilter;