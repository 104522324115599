export const ORGANIZATION_STATUS = {
    PENDING: "Pending",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
}

export const TYPE = {
    RF: "Reverse Factoring",
    F: "Factoring",
    IF: "International Factoring",
}
