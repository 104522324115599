import React, {useEffect, useState} from 'react';
import {classNames, sharedAccessTokenKey, sharedFetch,} from "../helpers/helpers";
import {BookOpenIcon, TrashIcon} from "@heroicons/react/solid";
import {useLogout} from "react-admin";
import {fireTimestampToDateInputString, fireTimestampToDateString} from "../utils";
import Spinner from "../components/spinner";

export default function Invoice({match}) {
    const {promissoryNoteId, id} = match.params ? match.params : {};
    const [isLoading, setIsLoading] = useState(false); // entry editing

    const [documentationId, setDocumentationId] = useState(promissoryNoteId || "")
    const [dueDate, setDueDate] = useState("")
    const [issueDate, setIssueDate] = useState("")
    const [status, setStatus] = useState("")
    const [number, setNumber] = useState("")
    const [agreedPaymentDate, setagreedPaymentDate] = useState("")
    //const [status, setStatus] = useState("")
    const [total, setTotal] = useState("")
    const [paidAmount, setPaidAmount] = useState("")
    const [transactions, setTransactions] = useState([])
    const logout = useLogout();

    useEffect(() => {
        promissoryNoteId && id && fetch()
    }, [promissoryNoteId, id]);

    function fetch() {
        setIsLoading(true)
        sharedFetch(`admin/finance/promissory-notes/${promissoryNoteId}/invoices/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then(data => {
            setDueDate(fireTimestampToDateInputString(data.dueDate) || "")
            setIssueDate(fireTimestampToDateInputString(data.date) || "")
            setStatus(data.status || "")
            setNumber(data.number || "")
            setagreedPaymentDate(fireTimestampToDateInputString(data.extendedPaymentDate) || "")
            //setStatus(data.status)
            setTotal(data.amount?.value || "")
            setPaidAmount(data.paidAmount?.value || "")
            setTransactions(data.transactions || [])
        }).finally(() => setIsLoading(false))
    }

    /*function onSubmit(e) {
        e.preventDefault();
        const object = {
            //status: status
        };

        const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            },
            body = JSON.stringify(object)

        sharedFetch(`invoices${id ? ("/" + id) : ""}`, {
            method: 'PUT',
            headers, body
        })
            /!*
            *!/.then(() => {
            // Create blob link to download

            fetch()
        }).catch(e => {
            if (e.status === 401) {
                logout();
            }
        })
    }*/

    function deleteTransactionEntry(transactionNumber) {
        if (window.confirm("Are you sure you wish to delete this transaction entry?")) {
            setIsLoading(true)
            sharedFetch(`admin/finance/promissory-notes/${promissoryNoteId}/invoices/${id}/transactions/${transactionNumber}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                },
            }).then(data => {
                fetch()
            }).finally(() => setIsLoading(false))
        }
    }

    return (
        <>
            <div className="my-8 space-y-6">
                {isLoading ? (
                    <Spinner/>
                ) : (
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                        <div className="space-y-8">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                                <div>
                                    <div>
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Invoice</h3>
                                        {id && (
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                Editing invoice entry with ID: {id}
                                            </p>
                                        )}
                                    </div>
                                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="type"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Promissory Note
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <div
                                                    className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                >
                                                    <div className="flex-shrink-0">
                                                        <BookOpenIcon className="h-10 w-10 rounded-full"/>
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <a href={"#/promissory-notes/" + documentationId}
                                                           className="focus:outline-none">
                                                            <span className="absolute inset-0" aria-hidden="true"/>
                                                            <p className="text-sm font-medium text-gray-900">Parent
                                                                Entry</p>
                                                            <p className="text-sm text-gray-500 truncate">Click to
                                                                open</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="status"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Invoice Status
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="text"
                                                    name="status"
                                                    id="status"
                                                    disabled={true}
                                                    value={status}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="number"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Invoice Number
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="text"
                                                    name="number"
                                                    id="number"
                                                    disabled={true}
                                                    value={number}
                                                    onChange={e => setNumber(e.target.value)}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="issueDate"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Invoice Date
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="date"
                                                    name="issueDate"
                                                    id="issueDate"
                                                    disabled={true}
                                                    value={issueDate}
                                                    onChange={e => setIssueDate(e.target.value)}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="dueDate"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Net Due Date
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="date"
                                                    name="dueDate"
                                                    id="dueDate"
                                                    disabled={true}
                                                    value={dueDate}
                                                    onChange={e => setDueDate(e.target.value)}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="agreedPaymentDate"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Extended Payment Date
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="date"
                                                    name="agreedPaymentDate"
                                                    id="agreedPaymentDate"
                                                    disabled={true}
                                                    value={agreedPaymentDate}
                                                    onChange={e => setagreedPaymentDate(e.target.value)}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="total"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Amount
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="text"
                                                    name="total"
                                                    id="total"
                                                    disabled={true}
                                                    value={total}
                                                    onChange={e => setTotal(e.target.value)}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="paidAmount"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Paid Amount
                                            </label>
                                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                <input
                                                    type="text"
                                                    name="paidAmount"
                                                    id="paidAmount"
                                                    disabled={true}
                                                    value={paidAmount}
                                                    className={classNames(
                                                        "max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm rounded-md",
                                                        true ? "border-gray-200 bg-gray-50 text-gray-500" : "border-gray-300"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="pt-12 flex items-center"><h4
                                            className="text-lg leading-6 font-medium text-gray-900">Transactions</h4>
                                        </div>
                                        <div className="mt-8 flex flex-col">
                                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div
                                                    className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                    <table className="min-w-full divide-y divide-gray-300">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col"
                                                                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                ID
                                                            </th>
                                                            <th scope="col"
                                                                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                Date
                                                            </th>
                                                            <th scope="col"
                                                                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                                                                Amount
                                                            </th>
                                                            <th scope="col"
                                                                className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                                                                <span className="sr-only">Edit</span>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200">
                                                        {transactions.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item.number}</td>
                                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{fireTimestampToDateString(item.date)}</td>
                                                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">{item.amount?.formatted}</td>
                                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                                    {item.number && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => deleteTransactionEntry(item.number)}
                                                                            className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                                        >
                                                                            <TrashIcon className="h-3 w-3"
                                                                                       aria-hidden="true"/>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {transactions.length === 0 && (
                                                            <tr>
                                                                <td colSpan={3}
                                                                    className="text-center whitespace-nowrap py-4 px-3 text-sm text-gray-500">No
                                                                    results
                                                                </td>
                                                            </tr>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/*<div className="pt-5">
                                <div className="flex justify-end">
                                    <a
                                        href="#/invoices"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        Cancel
                                    </a>
                                    <button
                                        type="submit"
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    >
                                        {id ? "Update" : "Create"}
                                    </button>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
};
