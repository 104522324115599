import React, {useEffect, useState} from 'react';
import {decimalNumberFormatter, httpClient} from "../helpers/helpers";
import * as Config from "../config";
import {useLogout, useRefresh} from "react-admin";
import {StatsList} from "./StatsList";

const columnsYearly = [
    { id: 'year', label: 'Year', minWidth: 40 },
    { id: 'amount', label: 'Total amount', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'pg', label: 'Amount PG', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'bg', label: 'Amount BG', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
];

const columnsMonthly = [
    { id: 'year', label: 'Year', minWidth: 40 },
    { id: 'month', label: 'Month', minWidth: 40 },
    { id: 'monthName', label: 'Month name', minWidth: 70 },
    { id: 'amount', label: 'Total amount', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'ytd', label: 'YTD', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'allTime', label: 'All time', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'pg', label: 'Amount PG', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'bg', label: 'Amount BG', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
];

async function investmentsStats() {
    return httpClient(`${Config.ADMIN_API_URL}/investments_stats`
    ).then(data => {
        switch (data.status) {
            case 401: throw new Error("Session expired!");
            default: return data.json
        }
    }).catch(error => {
        return error;
    })
}

export const InvestmentsStatsList = () => {
    const refresh = useRefresh();
    const logout = useLogout();

    const [data, setData] = useState({total: null, yearly: [], monthly: []})

    useEffect(() => {
        if (data.total === null) {
            investmentsStats()
                .then(d => {
                    if (d instanceof Error) throw d;
                    setData({
                        ...d,
                        yearly: d.yearly.reverse(),
                        monthly: d.monthly.reverse(),
                    })
                    refresh();
                })
                .catch(e => {
                    if (e.status === 401) {
                        logout();
                    }
                    console.log(e.body || e);
                })
        }
    }, [data, refresh, logout]);

    return (
        <div>
            Total investments: {data.total ? decimalNumberFormatter(data.total) : ''}
            <br/>
            <br/>
            <StatsList columns={columnsYearly} items={data.yearly} />
            <br />
            <StatsList columns={columnsMonthly} items={data.monthly} />
        </div>
    );
}