import {makeStyles} from "@material-ui/styles";

const useInputStyles = makeStyles({
    textField: {
        width: '20em',
        marginTop: '0.5em',
        marginBottom: '0.5em',
    },
    input: {
        "&:focus": {
            boxShadow: 'none'
        },
    },
    endAdornment: {
        padding: '0',
        margin: '0',
    },
    search: {
        padding: '0.5em 0 0.5em 0',
        margin: '0 0 0 1em',
        boxShadow: 'none',
    },
})

export default useInputStyles
