import React from "react";
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import ExportIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
    button: {
        margin: '0.5em',
        float: "right",
    },
});

const ExportButton = ({ onExport, loading }) => {
    const classes = useStyles();

    return (
        <Button variant="text" color="primary" startIcon={<ExportIcon />} className={classes.button} onClick={() => onExport()} disabled={loading}>Export</Button>
    );
};

export default ExportButton;
