import React, {useEffect, useState} from 'react';
import {decimalNumberFormatter, httpClient} from "../helpers/helpers";
import * as Config from "../config";
import {useLogout, useRefresh} from "react-admin";
import {StatsList} from "./StatsList";

const columns = [
    { id: 'year', label: 'Year', minWidth: 40 },
    { id: 'month', label: 'Month', minWidth: 40 },
    { id: 'deposits', label: 'Deposits', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'withdrawals', label: 'Withdrawals', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'diff', label: 'Diff', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'earnedInterests', label: 'Interests', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'taxAmount', label: 'Tax', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'totalFunds', label: 'Total funds', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'investedAmount', label: 'Invested', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'availableFunds', label: 'Available funds', minWidth: 70, align: 'right', format: (value) => decimalNumberFormatter(value) },
    { id: 'investedFundsRatio', label: '% invested', minWidth: 70, align: 'right', format: (value) => (100 * value).toFixed(0) },
];

async function fundsStats() {
    return httpClient(`${Config.ADMIN_API_URL}/funds_stats`
    ).then(data => {
        switch (data.status) {
            case 401: throw new Error("Session expired!");
            default: return data.json.monthly.reverse()
        }
    }).catch(error => {
        return error;
    })
}

export const FundsStatsList = () => {
    const refresh = useRefresh();
    const logout = useLogout();

    const [items, setItems] = useState(undefined)

    useEffect(() => {
        if (items === undefined) {
            fundsStats()
                .then(d => {
                    if (d instanceof Error) throw d;
                    setItems(d);
                    refresh();
                })
                .catch(e => {
                    if (e.status === 401) {
                        logout();
                    }
                    console.log(e.body || e);
                })
        }
    }, [items, refresh, logout]);

    return (
        <StatsList columns={columns} items={items} />
    );
}