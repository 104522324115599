import React, {Fragment, useEffect, useState} from 'react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    ExclamationIcon,
    FilterIcon,
    PencilIcon,
    SearchIcon,
    TrashIcon,
    XIcon
} from '@heroicons/react/solid'
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {PROMISSORY_NOTE_STATUS} from "./consts";
import {clickLink, getDateString, parsePlainDate, serializeQuery, toDecimal} from "../utils";
import Spinner from "../components/spinner";
import {Dialog, Transition} from '@headlessui/react'

const defaultOrderBy = "number",
    defaultOrderByDirection = "desc",
    defaultColumns = [
        {v: d => d.number || "-", label: "#", sort: "number", filter: "number", direction: defaultOrderByDirection},
        {
            v: d => d.debtorName || "-",
            label: "Debtor",
            sort: "debtorName",
            filter: "debtorName"
        },
        {
            v: d => d.date ? getDateString(new Date(d.date * 1000)) : "-",
            label: "Date",
            sort: "date",
            filter: "date"
        },
        {
            v: d => d.transactionDate ? getDateString(new Date(d.transactionDate * 1000)) : "-",
            label: "Transaction Date",
            sort: "transactionDate",
            filter: "transactionDate"
        },
        {
            v: d => toDecimal(d.amount) || "-",
            label: "Total",
            sort: "amount",
            filter: "amount"
        },
        {
            v: d => toDecimal(d.paidAmount) || "-",
            label: "Paid",
            sort: "paidAmount",
            filter: "paidAmount"
        },
        /*{v: d => "-", label: "Agreed Payment Date"},
        {v: d => "-", label: "Pending Payment"},
        {v: d => "-", label: "Paid"},*/
        {
            v: d => d.assigneeName || "-",
            label: "Assignee",
            sort: "assigneeName",
            filter: "assigneeName"
        },
        /*{v: d => "-", label: "Realization"},*/
        {
            v: d => d.type || "-",
            label: "Type",
            sort: "type",
            filter: "type"
        },
        {
            v: d => PROMISSORY_NOTE_STATUS[d.status] || d.status || "-",
            label: "Status",
            sort: "status",
            filter: "status"
        },
    ]

const limit = 1;

export const PromissoryNoteList = () => {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [result, setResult] = useState({})
    const [page, setPage] = useState(1)
    const logout = useLogout();
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [isOverdueFilterOn, setIsOverdueFilterOn] = useState(false)
    const [filterCount, setFilterCount] = useState(0)
    const [customFilter, setCustomFilter] = useState(null)
    const [searchQuery, setSearchQuery] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const [statusFilterDialogOpen, setStatusFilterDialogOpen] = useState(false)


    useEffect(() => {
        search()
    }, [orderBy, orderByDirection, page, isOverdueFilterOn, customFilter, searchQuery])

    function onSearchQuery(e) {
        setSearchQuery(e.target.value)
        setPage(1)
    }

    function search() {
        const query = serializeQuery({
            q: searchQuery,
            sort: orderBy ? `${orderBy}:${orderByDirection}` : null,
            filter: customFilter,
            page: page,
            //getIsProbablyOverdue: true,
            /*...(customFilter ? {
                orderBy,
                orderByDirection,
                offset,
                filter: customFilter
            } : {
                orderBy,
                orderByDirection,
                offset,
                ...(isOverdueFilterOn ? {
                    filter: ["isProbablyOverdue", "==", "true"]
                } : {})
            }),*/
            //sort: orderBy ? [orderBy + ":" + orderByDirection] : null,
        })
        sharedFetch(`admin/finance/promissory-notes/search?${query}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        }).then((json) => {
            //console.log("search", json)
            setResult(json)
            //setFilterCount(json?.totalOverdue || 0)
            /*setResult(json)
            setFilterCount(json?.totalOverdue || 0)*/
        })
    }

    async function createEntry() {
        setIsSubmitting(true)
        await sharedFetch(`admin/finance/promissory-notes/draft`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
            }
        })
            .then(data => {
                if (data?.id) {
                    clickLink("#/promissory-notes/" + data.id)
                } else if (data?.message) {
                    alert(data.message)
                } else {
                    alert("There was an error creating a new entry")
                }
            }).catch(e => {
                if (e.status === 401) {
                    logout();
                }
            }).finally(() => {
                setIsSubmitting(false)
            })
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.totalPages > page) setPage(page + 1)
    }

    function prevPage(e) {
        if (page > 1) setPage(page - 1)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        if (col.sort === defaultOrderBy && orderBy === col.sort) col.direction = "asc"
                        else col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
                setSearchQuery("")
                setPage(1)
            }
        }
    }

    function filter(columnIndex) {
        return function onClick(e) {
            if (columnIndex !== 8) e.preventDefault()
            const col = columns[columnIndex]
            if (col?.filter) {
                let placeholder = col.filter,
                    val = columnIndex !== 8 ? null : statusFilter,
                    filterVal = ""
                try {
                    switch (col.filter) {
                        case "number":
                            placeholder = "e.g. 2024-00001"
                            val = window.prompt("Filter by " + col.label, placeholder)
                            if (val) val = val.trim()
                            break;
                        case "debtorName":
                        case "assigneeName":
                            placeholder = "Enter company ID, registration or tax number"
                            val = window.prompt("Filter by " + col.label, placeholder)
                            if (val) val = val.trim()
                            const who = col.filter === "debtorName" ? "debtor" : "assignee"
                            filterVal = `${who}Oid = "${val}" OR ${who}RegistrationNo = "${val}" OR ${who}TaxNo = "${val}" OR ${who}VatNo = "${val}"`
                            break;
                        case "amount":
                        case "paidAmount":
                            placeholder = "e.g. 10,00"
                            val = window.prompt("Filter by " + col.label, placeholder)
                            if (val) val = Number(val.trim().replace(",", "."))
                            break;
                        case "date":
                        case "transactionDate":
                            placeholder = "Enter date in format DD.MM.YYYY"
                            val = parsePlainDate(window.prompt("Filter by " + col.label, placeholder))
                            if (val) val = new Date(val).getTime() / 1000
                            break;
                        case "type":
                            placeholder = "F or RF"
                            val = window.prompt("Filter by " + col.label, placeholder)
                            if (val) val = val.trim()
                            break;
                        case "status":
                            if (statusFilterDialogOpen) {
                                setStatusFilterDialogOpen(false)
                                setStatusFilter("")
                            } else {
                                setStatusFilterDialogOpen(true)
                                return
                            }
                            break;
                        default:
                            window.alert("Invalid filter")
                    }
                } catch (e) {
                    console.error(e)
                    window.alert("Invalid filter value")
                }

                if (val) {
                    setCustomFilter(filterVal || `${col.filter} = ${typeof val === "string" ? "'" + val + "'" : val}`)
                    setPage(1)
                    //setOrderBy(null)
                    //setOrderByDirection(null)
                } else removeCustomFilter()
            }
        }
    }

    /*function download(id) {
        return function onClick(e) {
            e.preventDefault()
            fetch(`promissory-notes/download/${id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(new Blob([blob]))
                    link.setAttribute('download', `doc.pdf`)
                    document.body.appendChild(link)
                    link.click()
                    link.parentNode.removeChild(link)
                });
        }
    }*/

    function deleteEntry(id) {
        return function onClick(e) {
            e.preventDefault()

            const ok = window.confirm("Are you sure you wish to delete this entry?")
            if (ok) sharedFetch(`admin/finance/promissory-notes/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
                }
            }).then(res => {
                search()
            });
        }
    }

    function toggleFilter(e) {
        e.preventDefault();
        const isFilterOnNewState = !isOverdueFilterOn
        setIsOverdueFilterOn(isFilterOnNewState)
    }

    function removeCustomFilter(e) {
        e?.preventDefault();

        setCustomFilter(null)
        setPage(1)
        setOrderBy(defaultOrderBy)
        setOrderByDirection(defaultOrderByDirection)
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="inline-flex text-xl font-semibold text-gray-900">Promissory Notes</h1>
                            {/*<p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>*/}
                            <div className="sm:px-8 inline-flex">
                                <button
                                    type="button"
                                    onClick={toggleFilter}
                                    className={classNames(
                                        "inline-flex rounded-full items-center py-1 text-sm font-medium box-border border-2",
                                        isOverdueFilterOn ? "pl-1 pr-1 border-current" : "pr-2.5 pl-1 border-transparent",
                                        filterCount > 0 ? "bg-red-100 text-red-700" : "bg-gray-100 text-gray-700"
                                    )}>
                                    <div
                                        className={classNames(
                                            "flex-shrink-0 mr-1 h-5 rounded-full inline-flex items-center justify-center",
                                            filterCount > 0 ? "text-red-400 bg-red-50" : "text-gray-400 bg-gray-50"
                                        )}
                                    >
                                        <span className="sr-only">Filter count</span>
                                        <span className="px-1">{filterCount}</span>
                                    </div>
                                    Overdue
                                    {isOverdueFilterOn && (
                                        <button
                                            type="button"
                                            onClick={toggleFilter}
                                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-400 hover:bg-red-200 hover:text-red-500 focus:outline-none focus:bg-red-500 focus:text-white"
                                        >
                                            <span className="sr-only">Remove overdue filter</span>
                                            <svg className="h-2 w-2" stroke="currentColor" fill="none"
                                                 viewBox="0 0 8 8">
                                                <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7"/>
                                            </svg>
                                        </button>
                                    )}
                                </button>
                                {customFilter && (
                                    <div className="border-l ml-3 pl-3">
                                        <button
                                            type="button"
                                            onClick={removeCustomFilter}
                                            className="inline-flex rounded-full items-center py-1 text-sm font-medium box-border border-2 bg-gray-100 text-gray-700 pl-2 pr-1 border-current">
                                            {/*<div
                                                className="flex-shrink-0 mr-1 h-5 rounded-full inline-flex items-center justify-center text-gray-400 bg-gray-50">
                                                <span className="sr-only">Filter count</span>
                                                <span className="px-1">{customFilterCount}</span>
                                            </div>*/}
                                            {customFilter}
                                            <button
                                                type="button"
                                                onClick={removeCustomFilter}
                                                className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-red-400 hover:bg-red-200 hover:text-red-500 focus:outline-none focus:bg-red-500 focus:text-white"
                                            >
                                                <span className="sr-only">Remove overdue filter</span>
                                                <svg className="h-2 w-2" stroke="currentColor" fill="none"
                                                     viewBox="0 0 8 8">
                                                    <path strokeLinecap="round" strokeWidth="1.5"
                                                          d="M1 1l6 6m0-6L1 7"/>
                                                </svg>
                                            </button>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                onClick={createEntry}
                                disabled={isSubmitting}
                                className="ml-3 inline-flex justify-center py-2 px-4 border text-sm font-medium rounded-md focus:outline-none text-white bg-gray-600 hover:bg-gray-700 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 border-transparent shadow-sm"
                            >
                                {isSubmitting ? "Creating..." : "Create new"}
                                {isSubmitting && <Spinner button/>}
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mt-8">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="relative mt-1 rounded-md shadow-sm">
                                <div
                                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                    aria-hidden="true"
                                >
                                    <SearchIcon className="h-4 w-4 text-gray-400" aria-hidden="true"/>
                                </div>
                                <input
                                    onChange={onSearchQuery}
                                    value={searchQuery}
                                    type="text"
                                    name="search"
                                    id="search"
                                    className="block w-full rounded-md border-0 py-1.5 pl-9 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900 border-r",
                                                        i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" : "px-3 py-3.5"
                                                    )}>
                                                    <span className="inline-flex">
                                                        <span onClick={sort(i)} className={classNames(
                                                            "group inline-flex truncate",
                                                            col.sort ? "cursor-pointer" : "cursor-default"
                                                        )}>
                                                            {col.label}
                                                            {col.sort && (
                                                                <span className={classNames(
                                                                    col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "opacity-50 ml-2 flex-none rounded text-gray-400 group-hover:opacity-100 group-focus:opacity-100"
                                                                )}>{col.direction === "asc" ? (
                                                                    <ChevronUpIcon className="h-5 w-5"
                                                                                   aria-hidden="true"/>
                                                                ) : col.direction === "desc" ? (
                                                                    <ChevronDownIcon className="h-5 w-5"
                                                                                     aria-hidden="true"/>
                                                                ) : <ChevronUpIcon className="h-5 w-5"
                                                                                   aria-hidden="true"/>}</span>
                                                            )}
                                                        </span>
                                                        {!!col.filter && (
                                                            <button onClick={filter(i)} type="button"
                                                                    className="ml-2 inline-block flex-none rounded text-gray-300 hover:bg-gray-200 hover:text-gray-500 p-1 -m-1">
                                                                <FilterIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </button>
                                                        )}
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {result?.hits?.map(data => (
                                            <tr key={data.id}>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            "text-sm",
                                                            i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" : "px-3 py-4 text-gray-500"
                                                        )}>
                                                        <span className="block max-w-xs truncate">{col.v(data)}</span>
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    {data.isProbablyOverdue === true && (
                                                        <div
                                                            className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-red-500 bg-red-100"
                                                            title="Overdue"
                                                        >
                                                            <ExclamationIcon className="h-3 w-3" aria-hidden="true"/>
                                                        </div>
                                                    )}
                                                    {/*<button
                                                        type="button"
                                                        onClick={download(data.id)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <DownloadIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>*/}
                                                    <button
                                                        type="button"
                                                        onClick={deleteEntry(data.id)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <TrashIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>
                                                    <a
                                                        href={"#/promissory-notes/" + data.id}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Page <span
                                                className="font-medium">{result?.page || "0"}</span> of <span
                                                className="font-medium">{result?.totalPages || "0"}</span>
                                                {/*<span className="font-medium">{result?.total || "0"}</span> results*/}
                                            </p>
                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <button
                                                type="button"
                                                onClick={prevPage}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    result?.page > 1 ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                onClick={nextPage}
                                                className={classNames(
                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (result?.totalPages > result?.page) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition.Root show={statusFilterDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setStatusFilterDialogOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                                            onClick={() => setStatusFilterDialogOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            Filter by status
                                        </Dialog.Title>
                                        <div className="mt-5">
                                            <label htmlFor="status-filter"
                                                   className="block text-sm font-medium leading-6 text-gray-900 sr-only">
                                                Status
                                            </label>
                                            <select
                                                id="status-filter"
                                                name="status-filter"
                                                value={statusFilter}
                                                onChange={e => setStatusFilter(e.target.value)}
                                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            >
                                                {Object.keys(PROMISSORY_NOTE_STATUS).map(key => (
                                                    <option key={key} value={key}>{PROMISSORY_NOTE_STATUS[key]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                                            onClick={() => filter(8)(false)}
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setStatusFilterDialogOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
};
