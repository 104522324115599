import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, RadioGroup, Transition} from '@headlessui/react'
import {classNames} from "../helpers/helpers";
import Spinner from "../components/spinner";

const plans = [
    {name: "Unsigned document", value: "UNSIGNED_DOCUMENT", limit: ''},
    {name: "Signed by supplier/creditor", value: "SIGNED_BY_ASSIGNEE", limit: ''},
    {name: "Signed by buyer/debtor", value: "SIGNED_BY_DEBTOR", limit: ''},
    {name: "Signed by both", value: "SIGNED_BY_ALL_PARTIES", limit: ''},
]

/*
 UNSIGNED_DOCUMENT = "UNSIGNED_DOCUMENT",
    SIGNED_BY_BUYER_DEBTOR = "SIGNED_BY_BUYER_DEBTOR",
    SIGNED_BY_ASSIGNEE = "SIGNED_BY_ASSIGNEE",
    SIGNED_BY_ALL_PARTIES = "SIGNED_BY_ALL_PARTIES",
 */

export default function UploadFileDialog({open, setOpen, onSubmit}) {
    const cancelButtonRef = useRef(null)

    const inputRef = useRef(null);

    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState(null);

    const [selected, setSelected] = useState(plans[0])
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (open) {
            setFile(null)
            setSelected(plans[0])
        }
    }, [open])

    useEffect(() => {
        if (!file && inputRef.current) {
            inputRef.current.value = null;
        }
    }, [file])

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };

    function handleFiles(files) {
        if (files.length > 1) {
            alert("Please select only one file")
            return
        }
        const file = files[0]
        if (file.type !== "application/pdf" && file.type !== "image/jpeg") {
            alert("Please select a PDF or JPEG file")
            return
        }
        setFile(file)
    }


    function onBeforeSubmit(e) {
        e.preventDefault()

        if (!selected?.value) {
            alert("Please select a type!")
            return
        }

        if (!file) {
            alert("Please select a file!")
            return
        }

        setIsSubmitting(true)

        onSubmit({file, type: selected.value}, (ok, err) => {

            if (err) alert(err)
            setIsSubmitting(false)
            setOpen(false)
        })
    }


    return (
        <Transition.Root show={!!open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                as="form"
                                onSubmit={onBeforeSubmit}
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/*<div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>*/}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                Upload
                                            </Dialog.Title>
                                            {/*<div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Fill in the form below to add a new partner.
                                                </p>
                                            </div>*/}
                                        </div>
                                    </div>

                                    <div className="space-y-6 sm:space-y-5">
                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-transparent sm:pt-5">
                                            <label htmlFor="cover-photo"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                File
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0 relative"
                                                 onDragEnter={handleDrag}>
                                                <div
                                                    className={classNames(
                                                        "flex justify-center rounded-md border-2 border-dashed px-6 pt-10 pb-11 transition duration-100 ease-in-out",
                                                        dragActive ? "bg-blue-50" : "border-gray-300"
                                                    )}>
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer rounded-md bg-transparent font-medium text-gray-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 hover:text-gray-500"
                                                            >
                                                                <span>Upload a file</span>
                                                                <input id="file-upload" name="file-upload" type="file"
                                                                       ref={inputRef}
                                                                       onChange={handleChange}
                                                                       className="sr-only"
                                                                       accept="image/jpeg, application/pdf"/>
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">PDF, JPG up to
                                                            10MB</p>
                                                    </div>
                                                    {file && (<div
                                                            className="absolute bottom-0 left-0 right-0 overflow-x-auto">
                                                            <div className="flex px-3 pb-2.5">
                                                                <span
                                                                    className="inline-flex items-center rounded-full bg-blue-100 py-0.5 pl-2 pr-0.5 text-xs font-medium text-blue-700">
                                                                    <span className="truncate">{file.name}</span>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => setFile(null)}
                                                                            className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500 focus:text-white focus:outline-none">
                                                                      <span className="sr-only">Remove</span>
                                                                      <svg className="h-2 w-2" stroke="currentColor"
                                                                           fill="none"
                                                                           viewBox="0 0 8 8">
                                                                        <path strokeLinecap="round" strokeWidth="1.5"
                                                                              d="M1 1l6 6m0-6L1 7"/>
                                                                      </svg>
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {dragActive && (
                                                    <div
                                                        className="absolute inset-0 border-2 border-dashed border-blue-300 rounded-md"
                                                        onDragEnter={handleDrag} onDragLeave={handleDrag}
                                                        onDragOver={handleDrag} onDrop={handleDrop}/>
                                                )}
                                            </div>
                                        </div>


                                        <div
                                            className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                            <label htmlFor="username"
                                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Type
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <RadioGroup value={selected} onChange={setSelected}>
                                                    <RadioGroup.Label className="sr-only"> Type </RadioGroup.Label>
                                                    <div className="relative -space-y-px rounded-md bg-white">
                                                        {plans.map((plan, planIdx) => (
                                                            <RadioGroup.Option
                                                                key={plan.value}
                                                                value={plan}
                                                                className={({checked}) =>
                                                                    classNames(
                                                                        planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                                        planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                                        checked ? 'bg-gray-50 border-gray-200 z-10' : 'border-gray-200',
                                                                        'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none'
                                                                    )
                                                                }
                                                            >
                                                                {({active, checked}) => (
                                                                    <>
                <span className="flex items-center text-sm">
                  <span
                      className={classNames(
                          checked ? 'bg-gray-600 border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-gray-500' : '',
                          'h-4 w-4 rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5"/>
                  </span>
                  <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-gray-900' : 'text-gray-900', 'ml-3 font-medium')}
                  >
                    {plan.name}
                  </RadioGroup.Label>
                </span>
                                                                        <RadioGroup.Description
                                                                            as="span"
                                                                            className={classNames(
                                                                                checked ? 'text-gray-700' : 'text-gray-500',
                                                                                'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                                                                            )}
                                                                        >
                                                                            {plan.limit}
                                                                        </RadioGroup.Description>
                                                                    </>
                                                                )}
                                                            </RadioGroup.Option>
                                                        ))}
                                                    </div>
                                                </RadioGroup>
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="inline-flex w-full justify-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        {isSubmitting ? "Uploading..." : "Upload"}
                                        {isSubmitting && <Spinner button/>}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
