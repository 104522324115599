import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {httpClient} from "../helpers/helpers";
import Spinner from "../components/spinner";
import {ADMIN_API_URL} from "../config";
import {useLogout} from "react-admin";
import {formatAddress} from "../utils";
import TableView from "../components/tableView";

const tableColumns = [
    {v: d => d.id, label: "Company ID"},
    {v: d => d.shortName || d.name, label: "Name"},
    {v: d => d.taxNumber, label: "Tax Number"},
    {v: d => formatAddress(d), label: "Address"}
]

export default function SearchCompaniesDialog({onSubmit, open, setOpen}) {
    const cancelButtonRef = useRef(null)

    const logout = useLogout()

    const [query, setQuery] = useState('')
    const [selectedItems, setSelectedItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [companies, setCompanies] = useState([])
    const [page, setPage] = useState(0)
    const [totalResults, setTotalResults] = useState(0)

    async function fetchCompanies(_query, _page = 0) {
        return httpClient(`${ADMIN_API_URL}/investmentCompanies?page=${_page}&size=${10}&sort=name,ASC${_query ? "&q=" + _query : ""}`).then(res => {
            //console.log(res.json.content)
            setCompanies(res.json.content)
            setPage(_page)
            setTotalResults(res.json.totalElements)
            setIsLoading(false)
        }).catch(e => {
            setIsLoading(false)
            if (e.status === 401) {
                logout();
            }
        })
    }

    useEffect(() => {
        if (open) {
            fetchCompanies()
        }
    }, [open])

    useEffect(() => {
        setIsLoading(true)
        const delayDebounceFn = setTimeout(() => {
            //console.log(query)
            // Send Axios request here
            fetchCompanies(query)

        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [query])

    function loadMore() {
        fetchCompanies(query, page + 1)
    }

    function onQueryChange(e) {
        setQuery(e.target.value)
        setPage(0)
    }

    function onSelectionChange(items) {
        //console.log("items", items)
        setSelectedItems(items)
    }

    function onBeforeSubmit(e) {
        e.preventDefault()

        if (selectedItems.length > 1) {
            alert("Please select only one company")
            return
        } else if (selectedItems.length === 0) {
            alert("Please select a company")
            return
        }

        onSubmit && onSubmit({...selectedItems[0], id: "" + selectedItems[0].id})

        setIsLoading(false)
        setOpen(false)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                as="div"
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/*<div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>*/}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                Select Company
                                            </Dialog.Title>
                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        <div className="sm:col-span-6">
                                            <div className="relative mt-1 flex items-center">
                                                <input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    value={query}
                                                    onChange={onQueryChange}
                                                    placeholder="Search..."
                                                    className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                                                />
                                                {/*<div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                                    <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
                                                        ⌘K
                                                    </kbd>
                                                </div>*/}
                                            </div>
                                        </div>
                                        <div className="sm:col-span-6">
                                            <div
                                                className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <TableView columns={tableColumns} data={companies}
                                                           onSelectionChange={onSelectionChange} onLoadMore={loadMore}
                                                           hasMore={totalResults > companies.length}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        disabled={isLoading}
                                        onClick={onBeforeSubmit}
                                        className="inline-flex w-full justify-center items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                    >
                                        Choose
                                        {isLoading && <Spinner button/>}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Dismiss
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
